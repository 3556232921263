export const graphicOrganizerEmpty_ela_2_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Adventure in Syllable City</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 15px;
            font-size: 28px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .building {
            margin: 30px 0;
            padding: 20px;
            border: 3px solid #233142;
            border-radius: 15px;
            background-color: white;
            position: relative;
        }
        .building-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
            text-align: center;
            background-color: #f0f7ff;
            padding: 8px;
            border-radius: 8px;
        }
        .word-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .syllable-boxes {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin: 15px 0;
        }
        .syllable-box {
            width: 100px;
            height: 50px;
            border: 2px solid #233142;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
        }
        .syllable-input {
            width: 90%;
            height: 80%;
            padding: 5px;
            text-align: center;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            border: 1px solid #aaa;
            border-radius: 5px;
        }
        .number-label {
            font-weight: bold;
            color: #233142;
            margin-top: 15px;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .number-input {
            width: 60px;
            padding: 8px;
            text-align: center;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            border: 1px solid #aaa;
            border-radius: 5px;
        }
        .path {
            height: 30px;
            border-left: 3px dashed #233142;
            margin-left: 50%;
        }
        .reflection {
            margin-top: 40px;
            padding: 20px;
            border: 3px solid #233142;
            border-radius: 15px;
            background-color: #fff8dc;
        }
        .reflection-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
            text-align: center;
        }
        .reflection-question {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .reflection-input {
            width: 100%;
            padding: 8px;
            margin-bottom: 15px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            border: 1px solid #aaa;
            border-radius: 5px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Adventure in Syllable City!</h1>
        
        <div class="instructions">
            <h3>Instructions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Say each word out loud.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Clap or tap once for each syllable you hear.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Write each syllable in its own box.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Count your claps and write the number of syllables.</div>
            </div>
        </div>
        
        <form class="form-container">
            <!-- Building 1 -->
            <div class="building">
                <div class="building-title">Butterfly Building</div>
                <div class="word-label">Word: butterfly</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" placeholder="but">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" placeholder="ter">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" placeholder="fly">
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <input type="text" class="number-input">
            </div>
            
            <div class="path"></div>
            
            <!-- Building 2 -->
            <div class="building">
                <div class="building-title">Adventure Avenue</div>
                <div class="word-label">Word: adventure</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input">
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <input type="text" class="number-input">
            </div>
            
            <div class="path"></div>
            
            <!-- Building 3 -->
            <div class="building">
                <div class="building-title">Special Stop</div>
                <div class="word-label">Word: special</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input">
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <input type="text" class="number-input">
            </div>
            
            <div class="path"></div>
            
            <!-- Building 4 -->
            <div class="building">
                <div class="building-title">Explore Emporium</div>
                <div class="word-label">Word: explore</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input">
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <input type="text" class="number-input">
            </div>
            
            <!-- Reflection Section -->
            <div class="reflection">
                <div class="reflection-title">Reflection</div>
                <div class="reflection-question">Which word was the trickiest?</div>
                <input type="text" class="reflection-input">
                <div class="reflection-question">Why?</div>
                <input type="text" class="reflection-input">
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ela_2_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Adventure in Syllable City</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 15px;
            font-size: 28px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .building {
            margin: 30px 0;
            padding: 20px;
            border: 3px solid #233142;
            border-radius: 15px;
            background-color: white;
            position: relative;
        }
        .building-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
            text-align: center;
            background-color: #f0f7ff;
            padding: 8px;
            border-radius: 8px;
        }
        .word-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .syllable-boxes {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin: 15px 0;
        }
        .syllable-box {
            width: 100px;
            height: 50px;
            border: 2px solid #233142;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
        }
        .syllable-input {
            width: 90%;
            height: 80%;
            padding: 5px;
            text-align: center;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            border: 1px solid #aaa;
            border-radius: 5px;
        }
        .number-label {
            font-weight: bold;
            color: #233142;
            margin-top: 15px;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .number-input {
            width: 60px;
            padding: 8px;
            text-align: center;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            border: 1px solid #aaa;
            border-radius: 5px;
        }
        .path {
            height: 30px;
            border-left: 3px dashed #233142;
            margin-left: 50%;
        }
        .reflection {
            margin-top: 40px;
            padding: 20px;
            border: 3px solid #233142;
            border-radius: 15px;
            background-color: #fff8dc;
        }
        .reflection-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
            text-align: center;
        }
        .reflection-question {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .reflection-input {
            width: 100%;
            padding: 8px;
            margin-bottom: 15px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            border: 1px solid #aaa;
            border-radius: 5px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Adventure in Syllable City!</h1>
        
        <div class="instructions">
            <h3>Instructions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Say each word out loud.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Clap or tap once for each syllable you hear.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Write each syllable in its own box.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Count your claps and write the number of syllables.</div>
            </div>
        </div>
        
        <form class="form-container">
            <!-- Building 1 -->
            <div class="building">
                <div class="building-title">Butterfly Building</div>
                <div class="word-label">Word: butterfly</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="but">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="ter">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="fly">
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <input type="text" class="number-input" value="3">
            </div>
            
            <div class="path"></div>
            
            <!-- Building 2 -->
            <div class="building">
                <div class="building-title">Adventure Avenue</div>
                <div class="word-label">Word: adventure</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="ad">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="ven">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="ture">
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <input type="text" class="number-input" value="3">
            </div>
            
            <div class="path"></div>
            
            <!-- Building 3 -->
            <div class="building">
                <div class="building-title">Special Stop</div>
                <div class="word-label">Word: special</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="spe">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="cial">
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <input type="text" class="number-input" value="2">
            </div>
            
            <div class="path"></div>
            
            <!-- Building 4 -->
            <div class="building">
                <div class="building-title">Explore Emporium</div>
                <div class="word-label">Word: explore</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="ex">
                    </div>
                    <div class="syllable-box">
                        <input type="text" class="syllable-input" value="plore">
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <input type="text" class="number-input" value="2">
            </div>
            
            <!-- Reflection Section -->
            <div class="reflection">
                <div class="reflection-title">Reflection</div>
                <div class="reflection-question">Which word was the trickiest?</div>
                <input type="text" class="reflection-input" value="adventure">
                <div class="reflection-question">Why?</div>
                <input type="text" class="reflection-input" value="It has 3 parts and was hard to hear where to break it.">
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ela_2_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Adventure in Syllable City</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 15px;
            font-size: 28px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .building {
            margin: 30px 0;
            padding: 20px;
            border: 3px solid #233142;
            border-radius: 15px;
            background-color: white;
            position: relative;
        }
        .building-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
            text-align: center;
            background-color: #f0f7ff;
            padding: 8px;
            border-radius: 8px;
        }
        .word-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .syllable-boxes {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin: 15px 0;
        }
        .syllable-box {
            width: 100px;
            height: 50px;
            border: 2px solid #233142;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            background-color: #ffffff;
        }
        .number-label {
            font-weight: bold;
            color: #233142;
            margin-top: 15px;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .number-box {
            width: 60px;
            height: 40px;
            border: 2px solid #233142;
            border-radius: 5px;
            background-color: #ffffff;
        }
        .path {
            height: 30px;
            border-left: 3px dashed #233142;
            margin-left: 50%;
        }
        .reflection {
            margin-top: 40px;
            padding: 20px;
            border: 3px solid #233142;
            border-radius: 15px;
            background-color: #fff8dc;
        }
        .reflection-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
            text-align: center;
        }
        .reflection-question {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .reflection-line {
            width: 100%;
            height: 40px;
            border: 2px solid #233142;
            border-radius: 5px;
            margin-bottom: 15px;
            background-color: #ffffff;
        }
        .example-text {
            color: #777;
            font-style: italic;
            text-align: center;
        }
        
        /* Print styles */
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Adventure in Syllable City!</h1>
        
        <div class="instructions">
            <h3>Instructions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Say each word out loud.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Clap or tap once for each syllable you hear.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Write each syllable in its own box.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Count your claps and write the number of syllables.</div>
            </div>
        </div>
        
        <div class="form-container">
            <!-- Building 1 -->
            <div class="building">
                <div class="building-title">Butterfly Building</div>
                <div class="word-label">Word: butterfly</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="example-text">but</div>
                    </div>
                    <div class="syllable-box">
                        <div class="example-text">ter</div>
                    </div>
                    <div class="syllable-box">
                        <div class="example-text">fly</div>
                    </div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <div class="number-box"></div>
            </div>
            
            <div class="path"></div>
            
            <!-- Building 2 -->
            <div class="building">
                <div class="building-title">Adventure Avenue</div>
                <div class="word-label">Word: adventure</div>
                <div class="syllable-boxes">
                    <div class="syllable-box"></div>
                    <div class="syllable-box"></div>
                    <div class="syllable-box"></div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <div class="number-box"></div>
            </div>
            
            <div class="path"></div>
            
            <!-- Building 3 -->
            <div class="building">
                <div class="building-title">Special Stop</div>
                <div class="word-label">Word: special</div>
                <div class="syllable-boxes">
                    <div class="syllable-box"></div>
                    <div class="syllable-box"></div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <div class="number-box"></div>
            </div>
            
            <div class="path"></div>
            
            <!-- Building 4 -->
            <div class="building">
                <div class="building-title">Explore Emporium</div>
                <div class="word-label">Word: explore</div>
                <div class="syllable-boxes">
                    <div class="syllable-box"></div>
                    <div class="syllable-box"></div>
                </div>
                <div class="number-label">Number of Syllables:</div>
                <div class="number-box"></div>
            </div>
            
            <!-- Reflection Section -->
            <div class="reflection">
                <div class="reflection-title">Reflection</div>
                <div class="reflection-question">Which word was the trickiest?</div>
                <div class="reflection-line"></div>
                <div class="reflection-question">Why?</div>
                <div class="reflection-line"></div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_ela_2_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Adventure Through Syllable City</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
            font-size: 28px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
            font-size: 20px;
        }
        p {
            line-height: 1.8;
            font-size: 18px;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 20px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Adventure Through Syllable City</h1>
        <div class="subtitle">Breaking Words Into Parts</div>
        
        <div class="instructions">
            <p>Clap the parts of big words as you read!</p>
        </div>
        
        <p>Sammy and Sophia found a big map. It led to a special town called Syllable City!</p>
        
        <p>"Let's go explore!" said Sophia.</p>
        
        <p>At Syllable City, they met Mayor Multi and Professor Phonic.</p>
        
        <p>"Words here break into parts called syllables," said Mayor Multi.</p>
        
        <p>Sammy tried breaking "butterfly" into syllables: "but-ter-fly!"</p>
        
        <p>They clapped and laughed as they learned new words.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Syllable Fun!</div>
            <p>Try clapping these words from the story:</p>
            <p>ad-ven-ture (3 claps)</p>
            <p>spe-cial (2 claps)</p>
            <p>ex-plore (2 claps)</p>
        </div>
    </div>
</body>
</html>`;

export const teacherResource_ela_2_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher Guide: Adventure Through Syllable City</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .objective-box {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .strategy-box {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .extension-box {
            background-color: #fff8dc;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .assessment-box {
            background-color: #f8e5ff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #e0c3e6;
        }
        h3 {
            color: #233142;
            margin-top: 0;
        }
        ul, ol {
            padding-left: 20px;
        }
        .teacher-header {
            background-color: #233142;
            color: white;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 20px;
            text-align: center;
        }
        .resource-summary {
            background-color: #f0f0f0;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .objective-box, .strategy-box, .extension-box, .assessment-box {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="teacher-header">
            <h2>TEACHER RESOURCE ONLY</h2>
        </div>
        
        <h1>Adventure Through Syllable City</h1>
        <div class="subtitle">Teacher Implementation Guide</div>
        
        <div class="resource-summary">
            <h3>Student Resource Summary:</h3>
            <p>This resource features a short story about characters Sammy and Sophia exploring "Syllable City" where they learn to break words into syllables. The student-facing content includes a fun narrative with multisyllabic words and a practice activity to clap syllables in words like "adventure," "special," and "explore."</p>
        </div>
        
        <div class="objective-box">
            <h3>Lesson Objectives:</h3>
            <ul>
                <li>Students will decode multisyllabic words by segmenting them into syllables</li>
                <li>Students will develop phonological awareness by identifying syllable breaks</li>
                <li>Students will practice reading fluency with multisyllabic words</li>
            </ul>
        </div>
        
        <div class="strategy-box">
            <h3>Teaching Strategies:</h3>
            <p><strong>Introduction:</strong> Use this short passage to introduce the concept of syllables as "word parts" or "beats" in words.</p>
            <p><strong>Modeling:</strong> Model how to clap syllable parts of words, starting with "butterfly" (but-ter-fly) as shown in the text.</p>
            <p><strong>Guided Practice:</strong> After reading the passage together, practice breaking down other multisyllabic words from the text:</p>
            <ul>
                <li>adventure (ad-ven-ture)</li>
                <li>special (spe-cial)</li>
                <li>explore (ex-plore)</li>
                <li>syllable (syl-la-ble)</li>
                <li>professor (pro-fes-sor)</li>
            </ul>
            <p><strong>Key Questions:</strong> Ask students, "How many syllable parts do you hear?" as you work through different words.</p>
            <p><strong>Kinesthetic Learning:</strong> Encourage students to clap or tap syllables to reinforce skill development and understanding.</p>
        </div>
        
        <div class="assessment-box">
            <h3>Assessment Opportunities:</h3>
            <ul>
                <li><strong>Formative Assessment:</strong> Listen to students as they clap out syllables, noting which students need additional support.</li>
                <li><strong>Group Activity:</strong> Have students work in pairs to identify syllables in words from classroom objects or a word wall.</li>
                <li><strong>Exit Ticket:</strong> Have students write 2-3 multisyllabic words and mark the syllable breaks.</li>
            </ul>
        </div>
        
        <div class="extension-box">
            <h3>Extension Activities:</h3>
            <ul>
                <li><strong>Syllable Sort:</strong> Provide cards with words having 1-4 syllables. Have students sort them by syllable count.</li>
                <li><strong>Syllable City Map:</strong> Create a classroom display where students can add multisyllabic words to different "neighborhoods" based on syllable count.</li>
                <li><strong>Syllable Detectives:</strong> During reading time, challenge students to find and record multisyllabic words from their independent reading books.</li>
                <li><strong>Writing Connection:</strong> Have students write their own short story using at least 5 multisyllabic words, then share with a partner who will identify the syllables.</li>
            </ul>
        </div>
        
        <div class="strategy-box">
            <h3>Differentiation:</h3>
            <ul>
                <li><strong>Support:</strong> For struggling students, begin with compound words where syllable breaks are more obvious (e.g., "base-ball," "rain-bow").</li>
                <li><strong>Challenge:</strong> For advanced students, introduce words with 4+ syllables or have them create their own "syllable dictionary" with more complex words.</li>
                <li><strong>ELL Support:</strong> Connect syllable practice to students' home languages, noting similarities and differences in syllable patterns.</li>
            </ul>
        </div>
        
        <div class="objective-box">
            <h3>South Carolina Standards Alignment:</h3>
            <p><strong>2.RL.13.1</strong> Engage in whole and small group reading with purpose and understanding.</p>
            <p><strong>2.RL.13.3</strong> Read and respond according to task and purpose to become self-directed, critical readers and thinkers.</p>
            <p><strong>2.F.3</strong> Demonstrate word analysis skills in decoding words.</p>
        </div>
    </div>
</body>
</html>`;