export const graphicOrganizerEmpty_ela_1_f_3_4_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Fun in Vowel Valley</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 28px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-size: 20px;
            margin-bottom: 30px;
        }
        .journey-map {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;
        }
        .location {
            flex: 1;
            border: 3px solid #233142;
            border-radius: 15px;
            padding: 20px;
            margin: 0 10px;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .location-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            text-align: center;
            margin-bottom: 15px;
        }
        .location-description {
            font-size: 18px;
            text-align: center;
            margin-bottom: 20px;
            line-height: 1.4;
        }
        .example {
            font-weight: bold;
            font-size: 22px;
            margin: 15px 0;
            text-align: center;
        }
        .prompt {
            font-size: 18px;
            margin: 15px 0 10px 0;
            text-align: center;
        }
        .input-field {
            width: 90%;
            padding: 10px;
            margin: 10px 0;
            border: 2px solid #aaa;
            border-radius: 10px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            text-align: center;
        }
        .reflection-box {
            border: 3px solid #233142;
            border-radius: 15px;
            padding: 20px;
            margin-top: 30px;
            width: 100%;
            box-sizing: border-box;
        }
        .reflection-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
        }
        .text-area {
            width: 100%;
            padding: 10px;
            margin: 10px 0;
            border: 2px solid #aaa;
            border-radius: 10px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            min-height: 60px;
            resize: vertical;
            box-sizing: border-box;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Fun in Vowel Valley!</h1>
        <div class="subtitle">Let's learn about vowel sounds in each syllable type!</div>
        
        <form>
            <div class="journey-map">
                <!-- Closed Cove Section -->
                <div class="location">
                    <div class="location-title">Closed Cove</div>
                    <div class="location-description">In Closed Cove, vowels sound short because a consonant closes them in.</div>
                    <div class="example">cat</div>
                    <div class="prompt" style="margin-bottom: 43px">Write another word with a short vowel here:</div>
                    <input type="text" class="input-field" name="closed_cove_word">
                </div>
                
                <!-- Open Oasis Section -->
                <div class="location">
                    <div class="location-title">Open Oasis</div>
                    <div class="location-description">In Open Oasis, vowels are open at the end and say their name (long sound).</div>
                    <div class="example">go</div>
                    <div class="prompt" style="margin-bottom: 16px">Why does 'go' have a long vowel sound? Write 'open' here:</div>
                    <input type="text" class="input-field" name="open_oasis_reason">
                </div>
                
                <!-- Magic-e Mountains Section -->
                <div class="location">
                    <div class="location-title">Magic-e Mountains</div>
                    <div class="location-description">In Magic-e Mountains, magic 'e' changes the vowel sound from short to long.</div>
                    <div class="example">kit → kite</div>
                    <div class="prompt">What letter makes 'kit' turn into 'kite'? Write it here:</div>
                    <input type="text" class="input-field" name="magic_e_letter">
                </div>
            </div>
            
            <!-- Reflection Section -->
            <div class="reflection-box">
                <div class="reflection-title">My Vowel Adventure</div>
                <div class="prompt">Write one sentence about what you learned about vowels today:</div>
                <textarea class="text-area" name="reflection"></textarea>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ela_1_f_3_4_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Fun in Vowel Valley</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 28px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-size: 20px;
            margin-bottom: 30px;
        }
        .journey-map {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;
        }
        .location {
            flex: 1;
            border: 3px solid #233142;
            border-radius: 15px;
            padding: 20px;
            margin: 0 10px;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .location-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            text-align: center;
            margin-bottom: 15px;
        }
        .location-description {
            font-size: 18px;
            text-align: center;
            margin-bottom: 20px;
            line-height: 1.4;
        }
        .example {
            font-weight: bold;
            font-size: 22px;
            margin: 15px 0;
            text-align: center;
        }
        .prompt {
            font-size: 18px;
            margin: 15px 0 10px 0;
            text-align: center;
        }
        .input-field {
            width: 90%;
            padding: 10px;
            margin: 10px 0;
            border: 2px solid #aaa;
            border-radius: 10px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            text-align: center;
        }
        .reflection-box {
            border: 3px solid #233142;
            border-radius: 15px;
            padding: 20px;
            margin-top: 30px;
            width: 100%;
            box-sizing: border-box;
        }
        .reflection-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
        }
        .text-area {
            width: 100%;
            padding: 10px;
            margin: 10px 0;
            border: 2px solid #aaa;
            border-radius: 10px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            min-height: 60px;
            resize: vertical;
            box-sizing: border-box;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Fun in Vowel Valley!</h1>
        <div class="subtitle">Let's learn about vowel sounds in each syllable type!</div>
        
        <form>
            <div class="journey-map">
                <!-- Closed Cove Section -->
                <div class="location">
                    <div class="location-title">Closed Cove</div>
                    <div class="location-description">In Closed Cove, vowels sound short because a consonant closes them in.</div>
                    <div class="example">cat</div>
                    <div class="prompt" style="margin-bottom: 37px">Write another word with a short vowel here:</div>
                    <input type="text" class="input-field" name="closed_cove_word" value="dog">
                </div>
                
                <!-- Open Oasis Section -->
                <div class="location">
                    <div class="location-title">Open Oasis</div>
                    <div class="location-description">In Open Oasis, vowels are open at the end and say their name (long sound).</div>
                    <div class="example">go</div>
                    <div class="prompt">Why does 'go' have a long vowel sound? Write 'open' here:</div>
                    <input type="text" class="input-field" name="open_oasis_reason" value="open">
                </div>
                
                <!-- Magic-e Mountains Section -->
                <div class="location">
                    <div class="location-title">Magic-e Mountains</div>
                    <div class="location-description">In Magic-e Mountains, magic 'e' changes the vowel sound from short to long.</div>
                    <div class="example">kit → kite</div>
                    <div class="prompt">What letter makes 'kit' turn into 'kite'? Write it here:</div>
                    <input type="text" class="input-field" name="magic_e_letter" value="e">
                </div>
            </div>
            
            <!-- Reflection Section -->
            <div class="reflection-box">
                <div class="reflection-title">My Vowel Adventure</div>
                <div class="prompt">Write one sentence about what you learned about vowels today:</div>
                <textarea class="text-area" name="reflection">I learned that magic e makes vowels say their name.</textarea>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ela_1_f_3_4_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Fun in Vowel Valley - Printable</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 28px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-size: 20px;
            margin-bottom: 30px;
        }
        .journey-map {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;
        }
        .location {
            flex: 1;
            border: 3px solid #233142;
            border-radius: 15px;
            padding: 20px;
            margin: 0 10px;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .location-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            text-align: center;
            margin-bottom: 15px;
        }
        .location-description {
            font-size: 18px;
            text-align: center;
            margin-bottom: 20px;
            line-height: 1.4;
        }
        .example {
            font-weight: bold;
            font-size: 22px;
            margin: 15px 0;
            text-align: center;
        }
        .prompt {
            font-size: 18px;
            margin: 15px 0 10px 0;
            text-align: center;
        }
        .writing-line {
            width: 90%;
            height: 2px;
            background-color: #aaa;
            margin: 15px auto;
            position: relative;
        }
        .writing-line:before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #ddd;
            position: absolute;
            top: 25px;
        }
        .reflection-box {
            border: 3px solid #233142;
            border-radius: 15px;
            padding: 20px;
            margin-top: 30px;
            width: 100%;
            box-sizing: border-box;
        }
        .reflection-title {
            font-weight: bold;
            color: #233142;
            font-size: 22px;
            margin-bottom: 15px;
        }
        .writing-lines {
            width: 100%;
            min-height: 60px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin: 10px 0;
            background-color: #ffffff;
            background-image: linear-gradient(#e5e5e5 1px, transparent 1px);
            background-size: 100% 30px;
            position: relative;
            box-sizing: border-box;
        }
        
        /* Print styles */
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Fun in Vowel Valley!</h1>
        <div class="subtitle">Let's learn about vowel sounds in each syllable type!</div>
        
        <div class="journey-map">
            <!-- Closed Cove Section -->
            <div class="location">
                <div class="location-title">Closed Cove</div>
                <div class="location-description">In Closed Cove, vowels sound short because a consonant closes them in.</div>
                <div class="example">cat</div>
                <div class="prompt" style="margin-bottom: 37px">Write another word with a short vowel here:</div>
                <div class="writing-line"></div>
            </div>
            
            <!-- Open Oasis Section -->
            <div class="location">
                <div class="location-title">Open Oasis</div>
                <div class="location-description">In Open Oasis, vowels are open at the end and say their name (long sound).</div>
                <div class="example">go</div>
                <div class="prompt">Why does 'go' have a long vowel sound? Write 'open' here:</div>
                <div class="writing-line"></div>
            </div>
            
            <!-- Magic-e Mountains Section -->
            <div class="location">
                <div class="location-title">Magic-e Mountains</div>
                <div class="location-description">In Magic-e Mountains, magic 'e' changes the vowel sound from short to long.</div>
                <div class="example">kit → kite</div>
                <div class="prompt">What letter makes 'kit' turn into 'kite'? Write it here:</div>
                <div class="writing-line"></div>
            </div>
        </div>
        
        <!-- Reflection Section -->
        <div class="reflection-box">
            <div class="reflection-title">My Vowel Adventure</div>
            <div class="prompt">Write one sentence about what you learned about vowels today:</div>
            <div class="writing-lines"></div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_ela_1_f_3_4_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Fun in Vowel Valley!</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
            font-size: 32px;
        }
        p {
            line-height: 1.8;
            font-size: 20px;
            margin-bottom: 20px;
            color: #333;
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 22px;
        }
        .fun-fact p {
            font-size: 20px;
            margin-bottom: 10px;
        }
        .place {
            color: #3fa672;
            font-weight: bold;
            font-size: 22px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Fun in Vowel Valley!</h1>
        
        <p>We go to <span class="place">Closed Cove</span>.</p>
        
        <p>Cat and dog run fast.</p>
        
        <p>We see <span class="place">Open Oasis</span> next.</p>
        
        <p>Leo Lion says, "Go, go, go!"</p>
        
        <p>In <span class="place">Magic-e Mountains</span>, Kit gets a kite!</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Vowel Valley Places</div>
            <p>Closed Cove - where vowels make short sounds</p>
            <p>Open Oasis - where vowels make long sounds</p>
            <p>Magic-e Mountains - where e makes vowels say their name</p>
        </div>
    </div>
</body>
</html>`;

export const teacherResource_ela_1_f_3_4_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher's Guide: Fun in Vowel Valley</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        h2 {
            color: #233142;
            margin-top: 25px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .teacher-section {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .teacher-section h3 {
            color: #2e7d32;
            margin-top: 0;
        }
        .discussion {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .discussion h3 {
            color: #233142;
            margin-top: 0;
        }
        .extension {
            background-color: #fff8dc;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .extension h3 {
            color: #233142;
            margin-top: 0;
        }
        .warning {
            background-color: #ffebee;
            color: #c62828;
            padding: 10px;
            border-radius: 5px;
            margin: 15px 0;
            border-left: 4px solid #c62828;
        }
        .summary {
            background-color: #f3f3f3;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border-left: 4px solid #233142;
        }
        ul, ol {
            padding-left: 25px;
        }
        li {
            margin-bottom: 8px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .teacher-section, .discussion, .extension {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>TEACHER'S GUIDE: Fun in Vowel Valley</h1>
        <div class="subtitle">First Grade ELA - Vowel Patterns Instruction</div>
        
        <div class="warning">
            <strong>FOR TEACHER USE ONLY</strong> - This document contains instructional notes and should not be distributed to students.
        </div>
        
        <div class="summary">
            <h3>Resource Summary</h3>
            <p>This student resource introduces three different vowel patterns through a journey to "Vowel Valley" with locations representing different syllable types: Closed Cove (short vowels), Open Oasis (long vowels), and Magic-e Mountains (vowel-consonant-e patterns). The text uses simple sentences with examples of each pattern to help students visualize and remember vowel sound rules.</p>
        </div>
        
        <div class="teacher-section">
            <h3>Lesson Objective</h3>
            <p>Students will identify vowel sounds and decode simple one-syllable words using closed, open, and vowel-consonant-e syllable patterns.</p>
            
            <h3>SC Standards Alignment</h3>
            <ul>
                <li>1.RL.13.1 - Identify and explain the literary devices of rhyme, alliteration, and onomatopoeia.</li>
                <li>1.RL.5.1 - Ask and answer who, what, when, where, why, and how questions to demonstrate understanding of a text.</li>
                <li>1.RL.10.1 - Use knowledge of phonics and word analysis skills to decode words.</li>
            </ul>
        </div>
        
        <div class="teacher-section">
            <h3>Using the Resource</h3>
            <ul>
                <li>Read each sentence aloud first, then have students echo-read.</li>
                <li>Point out vowel sounds clearly, emphasizing short vowels (cat, dog) in Closed Cove, long vowels in Open Oasis ("go"), and the magic-e effect (kit → kite).</li>
                <li>Prompt students to identify vowels and syllable types for each highlighted word.</li>
                <li>Use hand motions to reinforce concepts: 
                    <ul>
                        <li>Closed syllable: make a "closing" motion with hands</li>
                        <li>Open syllable: open hands wide</li>
                        <li>Magic-e: wave hand like a magic wand</li>
                    </ul>
                </li>
                <li>After reading, have students sort word cards into the three "places" in Vowel Valley.</li>
            </ul>
        </div>
        
        <div class="discussion">
            <h3>Discussion Questions</h3>
            <ol>
                <li>What vowel sound do you hear in "cat"? (short "a")</li>
                <li>Why does "go" sound long? (It's an open syllable; no consonant after the vowel.)</li>
                <li>How did "kit" change to "kite"? (magic e made the vowel sound long)</li>
                <li>Can you think of other words that would belong in Closed Cove? (Examples: pet, sit, hot, rug)</li>
                <li>What words might we find in Open Oasis? (Examples: me, hi, no, we)</li>
                <li>How does Magic-e Mountain change words? (Examples: changing hop to hope, tub to tube)</li>
            </ol>
        </div>
        
        <div class="extension">
            <h3>Activity Extensions</h3>
            <ul>
                <li><strong>Word Transformation:</strong> Have students use letter tiles or whiteboards to change short vowel words to magic-e words (e.g., hop → hope, cub → cube).</li>
                <li><strong>Vowel Valley Map:</strong> Create a classroom map of Vowel Valley with the three locations. Students can add word cards to the appropriate locations throughout the week.</li>
                <li><strong>Sorting Game:</strong> Provide students with word cards to sort into three piles based on vowel pattern.</li>
                <li><strong>Vowel Pattern Hunt:</strong> Have students search for examples of each vowel pattern in their independent reading books.</li>
            </ul>
        </div>
        
        <div class="teacher-section">
            <h3>Assessment Opportunities</h3>
            <ul>
                <li><strong>Formative:</strong> Observe students during echo reading and discussions to gauge understanding.</li>
                <li><strong>Word Sorting:</strong> Assess accuracy when students sort words into different vowel pattern categories.</li>
                <li><strong>Quick Check:</strong> Have students identify the vowel sound in a list of 5-6 words representing different patterns.</li>
                <li><strong>Application:</strong> Have students read a new short text and identify examples of each vowel pattern.</li>
            </ul>
        </div>
        
        <div class="teacher-section">
            <h3>Differentiation Strategies</h3>
            
            <h4>For Students Needing Support:</h4>
            <ul>
                <li>Focus on just one vowel pattern at a time</li>
                <li>Provide picture support with words</li>
                <li>Use color-coding for different vowel patterns</li>
                <li>Work in small teacher-guided groups</li>
            </ul>
            
            <h4>For Students Needing Challenge:</h4>
            <ul>
                <li>Introduce multisyllabic words with mixed patterns</li>
                <li>Have students create their own "Vowel Valley" stories</li>
                <li>Introduce additional syllable types (r-controlled, vowel teams)</li>
                <li>Challenge students to find patterns in grade-level texts</li>
            </ul>
        </div>
        
        <div class="teacher-section">
            <h3>Materials Needed</h3>
            <ul>
                <li>Student copies of "Fun in Vowel Valley"</li>
                <li>Letter tiles or magnetic letters</li>
                <li>Word cards for sorting activities</li>
                <li>Chart paper for creating Vowel Valley map</li>
                <li>Whiteboards and markers</li>
            </ul>
        </div>
        
        <div class="extension">
            <h3>Home Connection</h3>
            <p>Send home a family letter explaining the three vowel patterns with examples. Include a simple word sort activity that families can do together to reinforce the concepts.</p>
        </div>
    </div>
</body>
</html>`;