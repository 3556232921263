export const graphicOrganizerEmpty_math_algebra_1_A1_FLQE_2_ = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Linear & Exponential Functions Graphic Organizer</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .comparison-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        .comparison-table th {
            background-color: #233142;
            color: white;
            padding: 12px;
            text-align: center;
            font-weight: bold;
            border: 2px solid #ddd;
        }
        .comparison-table td {
            border: 2px solid #ddd;
            padding: 10px;
            vertical-align: top;
        }
        .section-header {
            font-weight: bold;
            margin-bottom: 5px;
            color: #233142;
            font-size: 16px;
            margin-top: 8px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            background-color: #ffffff;
            box-sizing: border-box;
            margin-top: 5px;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
            box-sizing: border-box;
            margin-top: 5px;
        }
        .mini-table {
            width: 100%;
            border-collapse: collapse;
            margin: 10px 0;
        }
        .mini-table th, .mini-table td {
            border: 1px solid #aaa;
            padding: 5px;
            text-align: center;
        }
        .mini-table th {
            background-color: #233142;
        }
        .formula-box {
            font-size: 1em;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Pattern Detectives: Linear & Exponential Growth Explorer</h1>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Use this table to clearly compare how linear and exponential functions grow.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Notice how each column is different. Think about what makes each function unique.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Use these examples to help you identify linear and exponential functions in other problems.</div>
            </div>
        </div>
        
        <div class="organizer-sheet">
            <table class="comparison-table">
                <tr>
                    <th>LINEAR FUNCTIONS</th>
                    <th>EXPONENTIAL FUNCTIONS</th>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Definition:</div>
                        <div>Grows by ADDING the same amount every step.</div>
                        <textarea class="text-area" placeholder="Add your notes about linear functions..."></textarea>
                    </td>
                    <td>
                        <div class="section-header">Definition:</div>
                        <div>Grows by MULTIPLYING by the same amount every step.</div>
                        <textarea class="text-area" placeholder="Add your notes about exponential functions..."></textarea>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Key Vocabulary:</div>
                        <div>- Constant Rate (slope)</div>
                        <div>- Starting Amount (Initial value)</div>
                        <textarea class="text-area" placeholder="Add more vocabulary terms..."></textarea>
                    </td>
                    <td>
                        <div class="section-header">Key Vocabulary:</div>
                        <div>- Multiplying Factor</div>
                        <div>- Starting Amount (Initial value)</div>
                        <div>- Double/Triple (Repeated Multiplying)</div>
                        <textarea class="text-area" placeholder="Add more vocabulary terms..."></textarea>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Example 1: West End Population</div>
                        <table class="mini-table">
                            <tr>
                                <th>Years</th>
                                <th>Population</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>350</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>370</td>
                                <td>+20</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>390</td>
                                <td>+20</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>410</td>
                                <td>+20</td>
                            </tr>
                        </table>
                        <div class="section-header">What pattern do you notice?</div>
                        <textarea class="text-area" placeholder="Describe the pattern..."></textarea>
                    </td>
                    <td>
                        <div class="section-header">Example 1: East Hills Population</div>
                        <table class="mini-table">
                            <tr>
                                <th>Years</th>
                                <th>Population</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>50</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>100</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>200</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>400</td>
                                <td>x2</td>
                            </tr>
                        </table>
                        <div class="section-header">What pattern do you notice?</div>
                        <textarea class="text-area" placeholder="Describe the pattern..."></textarea>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Symbolic Rule for West End:</div>
                        <div class="formula-box">y = 20x + 350</div>
                        <div class="section-header">Explain what each part means:</div>
                        <input type="text" class="input-field" placeholder="What does 20 represent?">
                        <input type="text" class="input-field" placeholder="What does 350 represent?">
                    </td>
                    <td>
                        <div class="section-header">Symbolic Rule for East Hills:</div>
                        <div class="formula-box">y = 50(2)^x</div>
                        <div class="section-header">Explain what each part means:</div>
                        <input type="text" class="input-field" placeholder="What does 50 represent?">
                        <input type="text" class="input-field" placeholder="What does 2 represent?">
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Example 2: Vendor A Popcorn</div>
                        <table class="mini-table">
                            <tr>
                                <th>Bags</th>
                                <th>Cost</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>$3.00</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>$4.00</td>
                                <td>+$1.00</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>$5.00</td>
                                <td>+$1.00</td>
                            </tr>
                        </table>
                        <div class="section-header">Symbolic Rule:</div>
                        <div class="formula-box">y = 1x + 2</div>
                    </td>
                    <td>
                        <div class="section-header">Example 2: Vendor B Popcorn</div>
                        <table class="mini-table">
                            <tr>
                                <th>Bags</th>
                                <th>Cost</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>$0.01</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>$0.03</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>$0.07</td>
                                <td>x2</td>
                            </tr>
                        </table>
                        <div class="section-header">Symbolic Rule:</div>
                        <div class="formula-box">y = 0.01(2^x - 1)</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">GENERIC SYMBOLIC FORM:</div>
                        <div class="formula-box">y = mx + b</div>
                        <div>m = constant rate</div>
                        <div>b = initial value</div>
                        <div class="section-header">Create your own linear example:</div>
                        <textarea class="text-area" placeholder="Write a real-world scenario that follows a linear pattern..."></textarea>
                        <div class="section-header">Your symbolic rule:</div>
                        <input type="text" class="input-field" placeholder="Write your rule in the form y = mx + b">
                    </td>
                    <td>
                        <div class="section-header">GENERIC SYMBOLIC FORM:</div>
                        <div class="formula-box">y = a(b)^x</div>
                        <div>a = initial value</div>
                        <div>b = multiplying factor</div>
                        <div class="section-header">Create your own exponential example:</div>
                        <textarea class="text-area" placeholder="Write a real-world scenario that follows an exponential pattern..."></textarea>
                        <div class="section-header">Your symbolic rule:</div>
                        <input type="text" class="input-field" placeholder="Write your rule in the form y = a(b)^x">
                    </td>
                </tr>
            </table>
            
            <div style="margin-top: 20px;">
                <div class="section-header">Reflection: How can you tell if a function is linear or exponential?</div>
                <textarea class="text-area" style="min-height: 80px;" placeholder="Write your thoughts here..."></textarea>
            </div>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_math_algebra_1_A1_FLQE_2_ = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Linear & Exponential Functions Graphic Organizer</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .comparison-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        .comparison-table th {
            background-color: #233142;
            color: white;
            padding: 12px;
            text-align: center;
            font-weight: bold;
            border: 2px solid #ddd;
        }
        .comparison-table td {
            border: 2px solid #ddd;
            padding: 10px;
            vertical-align: top;
        }
        .section-header {
            font-weight: bold;
            margin-bottom: 5px;
            color: #233142;
            font-size: 16px;
            margin-top: 8px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            background-color: #ffffff;
            box-sizing: border-box;
            margin-top: 5px;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
            box-sizing: border-box;
            margin-top: 5px;
        }
        .mini-table {
            width: 100%;
            border-collapse: collapse;
            margin: 10px 0;
        }
        .mini-table th, .mini-table td {
            border: 1px solid #aaa;
            padding: 5px;
            text-align: center;
        }
        .mini-table th {
            background-color: #233142;
        }
        .formula-box {
            font-size: 1em;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Pattern Detectives: Linear & Exponential Growth Explorer</h1>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Use this table to clearly compare how linear and exponential functions grow.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Notice how each column is different. Think about what makes each function unique.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Use these examples to help you identify linear and exponential functions in other problems.</div>
            </div>
        </div>
        
        <div class="organizer-sheet">
            <table class="comparison-table">
                <tr>
                    <th>LINEAR FUNCTIONS</th>
                    <th>EXPONENTIAL FUNCTIONS</th>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Definition:</div>
                        <div>Grows by ADDING the same amount every step.</div>
                        <textarea class="text-area" placeholder="Add your notes about linear functions...">Lin's neighborhood grows by adding 20 people each year. Linear functions always add the same number each time.</textarea>
                    </td>
                    <td>
                        <div class="section-header">Definition:</div>
                        <div>Grows by MULTIPLYING by the same amount every step.</div>
                        <textarea class="text-area" placeholder="Add your notes about exponential functions...">Ella's neighborhood doubles every year. Exponential functions always multiply by the same number each time.</textarea>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Key Vocabulary:</div>
                        <div>- Constant Rate (slope)</div>
                        <div>- Starting Amount (Initial value)</div>
                        <textarea class="text-area" placeholder="Add more vocabulary terms...">- Arithmetic sequence
- Add the same amount
- Linear growth</textarea>
                    </td>
                    <td>
                        <div class="section-header">Key Vocabulary:</div>
                        <div>- Multiplying Factor</div>
                        <div>- Starting Amount (Initial value)</div>
                        <div>- Double/Triple (Repeated Multiplying)</div>
                        <textarea class="text-area" placeholder="Add more vocabulary terms...">- Geometric sequence
- Growth factor
- Exponential growth</textarea>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Example 1: West End Population</div>
                        <table class="mini-table">
                            <tr>
                                <th>Years</th>
                                <th>Population</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>350</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>370</td>
                                <td>+20</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>390</td>
                                <td>+20</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>410</td>
                                <td>+20</td>
                            </tr>
                        </table>
                        <div class="section-header">What pattern do you notice?</div>
                        <textarea class="text-area" placeholder="Describe the pattern...">The population increases by exactly 20 people each year. The change is always +20, so it's a linear pattern.</textarea>
                    </td>
                    <td>
                        <div class="section-header">Example 1: East Hills Population</div>
                        <table class="mini-table">
                            <tr>
                                <th>Years</th>
                                <th>Population</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>50</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>100</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>200</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>400</td>
                                <td>x2</td>
                            </tr>
                        </table>
                        <div class="section-header">What pattern do you notice?</div>
                        <textarea class="text-area" placeholder="Describe the pattern...">The population doubles each year. The number of people is multiplied by 2 each time, so it's an exponential pattern.</textarea>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Symbolic Rule for West End:</div>
                        <div class="formula-box">y = 20x + 350</div>
                        <div class="section-header">Explain what each part means:</div>
                        <input type="text" class="input-field" placeholder="What does 20 represent?" value="20 is how many people are added each year">
                        <input type="text" class="input-field" placeholder="What does 350 represent?" value="350 is the starting population">
                    </td>
                    <td>
                        <div class="section-header">Symbolic Rule for East Hills:</div>
                        <div class="formula-box">y = 50(2)^x</div>
                        <div class="section-header">Explain what each part means:</div>
                        <input type="text" class="input-field" placeholder="What does 50 represent?" value="50 is the starting population">
                        <input type="text" class="input-field" placeholder="What does 2 represent?" value="2 is the multiplying factor (doubles each year)">
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Example 2: Vendor A Popcorn</div>
                        <table class="mini-table">
                            <tr>
                                <th>Bags</th>
                                <th>Cost</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>$3.00</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>$4.00</td>
                                <td>+$1.00</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>$5.00</td>
                                <td>+$1.00</td>
                            </tr>
                        </table>
                        <div class="section-header">Symbolic Rule:</div>
                        <div class="formula-box">y = 1x + 2</div>
                    </td>
                    <td>
                        <div class="section-header">Example 2: Vendor B Popcorn</div>
                        <table class="mini-table">
                            <tr>
                                <th>Bags</th>
                                <th>Cost</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>$0.01</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>$0.03</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>$0.07</td>
                                <td>x2</td>
                            </tr>
                        </table>
                        <div class="section-header">Symbolic Rule:</div>
                        <div class="formula-box">y = 0.01(2^x - 1)</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">GENERIC SYMBOLIC FORM:</div>
                        <div class="formula-box">y = mx + b</div>
                        <div>m = constant rate</div>
                        <div>b = initial value</div>
                        <div class="section-header">Create your own linear example:</div>
                        <textarea class="text-area" placeholder="Write a real-world scenario that follows a linear pattern...">A swimming pool starts with 500 gallons of water. The owner adds 25 gallons every hour to fill it up.</textarea>
                        <div class="section-header">Your symbolic rule:</div>
                        <input type="text" class="input-field" placeholder="Write your rule in the form y = mx + b" value="y = 25x + 500">
                    </td>
                    <td>
                        <div class="section-header">GENERIC SYMBOLIC FORM:</div>
                        <div class="formula-box">y = a(b)^x</div>
                        <div>a = initial value</div>
                        <div>b = multiplying factor</div>
                        <div class="section-header">Create your own exponential example:</div>
                        <textarea class="text-area" placeholder="Write a real-world scenario that follows an exponential pattern...">A savings account starts with $200 and earns 5% interest compounded monthly. Each month, the total amount grows by multiplying the previous balance by 1.05.</textarea>
                        <div class="section-header">Your symbolic rule:</div>
                        <input type="text" class="input-field" placeholder="Write your rule in the form y = a(b)^x" value="y = 10(3)^x">
                    </td>
                </tr>
            </table>
            
            <div style="margin-top: 20px;">
                <div class="section-header">Reflection: How can you tell if a function is linear or exponential?</div>
                <textarea class="text-area" style="min-height: 80px;" placeholder="Write your thoughts here...">Linear functions add the same amount each time, like adding 20 people every year. The table shows the same difference between values. Exponential functions multiply by the same number each time, like doubling every year. The numbers grow much faster in exponential functions.</textarea>
            </div>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_math_algebra_1_A1_FLQE_2_ = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Linear & Exponential Functions Graphic Organizer</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .comparison-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        .comparison-table th {
            background-color: #233142;
            color: white;
            padding: 12px;
            text-align: center;
            font-weight: bold;
            border: 2px solid #ddd;
        }
        .comparison-table td {
            border: 2px solid #ddd;
            padding: 10px;
            vertical-align: top;
        }
        .section-header {
            font-weight: bold;
            margin-bottom: 5px;
            color: #233142;
            font-size: 16px;
            margin-top: 8px;
        }
        .writing-area {
            width: 100%;
            min-height: 60px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color: #ffffff;
            position: relative;
            box-sizing: border-box;
            margin-top: 5px;
        }
        .writing-lines {
            width: 100%;
            min-height: 60px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color: #ffffff;
            background-image: linear-gradient(#e5e5e5 1px, transparent 1px);
            background-size: 100% 25px;
            position: relative;
            box-sizing: border-box;
            margin-top: 5px;
        }
        .mini-table {
            width: 100%;
            border-collapse: collapse;
            margin: 10px 0;
        }
        .mini-table th, .mini-table td {
            border: 1px solid #aaa;
            padding: 5px;
            text-align: center;
        }
        .mini-table th {
            background-color: #233142;
        }
        .formula-box {
            font-size: 1em;
        }
        .response-line {
            width: 100%;
            height: 30px;
            border-bottom: 1px solid #aaa;
            margin-bottom: 10px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Pattern Detectives: Linear & Exponential Growth Explorer</h1>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Use this table to clearly compare how linear and exponential functions grow.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Notice how each column is different. Think about what makes each function unique.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Use these examples to help you identify linear and exponential functions in other problems.</div>
            </div>
        </div>
        
        <div class="organizer-sheet">
            <table class="comparison-table">
                <tr>
                    <th>LINEAR FUNCTIONS</th>
                    <th>EXPONENTIAL FUNCTIONS</th>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Definition:</div>
                        <div>Grows by ADDING the same amount every step.</div>
                        <div class="writing-lines"></div>
                    </td>
                    <td>
                        <div class="section-header">Definition:</div>
                        <div>Grows by MULTIPLYING by the same amount every step.</div>
                        <div class="writing-lines"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Key Vocabulary:</div>
                        <div>- Constant Rate (slope)</div>
                        <div>- Starting Amount (Initial value)</div>
                        <div class="writing-lines"></div>
                    </td>
                    <td>
                        <div class="section-header">Key Vocabulary:</div>
                        <div>- Multiplying Factor</div>
                        <div>- Starting Amount (Initial value)</div>
                        <div>- Double/Triple (Repeated Multiplying)</div>
                        <div class="writing-lines"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Example 1: West End Population</div>
                        <table class="mini-table">
                            <tr>
                                <th>Years</th>
                                <th>Population</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>350</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>370</td>
                                <td>+20</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>390</td>
                                <td>+20</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>410</td>
                                <td>+20</td>
                            </tr>
                        </table>
                        <div class="section-header">What pattern do you notice?</div>
                        <div class="writing-lines"></div>
                    </td>
                    <td>
                        <div class="section-header">Example 1: East Hills Population</div>
                        <table class="mini-table">
                            <tr>
                                <th>Years</th>
                                <th>Population</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>50</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>100</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>200</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>400</td>
                                <td>x2</td>
                            </tr>
                        </table>
                        <div class="section-header">What pattern do you notice?</div>
                        <div class="writing-lines"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Symbolic Rule for West End:</div>
                        <div class="formula-box">y = 20x + 350</div>
                        <div class="section-header">Explain what each part means:</div>
                        <div>What does 20 represent?</div>
                        <div class="response-line"></div>
                        <div>What does 350 represent?</div>
                        <div class="response-line"></div>
                    </td>
                    <td>
                        <div class="section-header">Symbolic Rule for East Hills:</div>
                        <div class="formula-box">y = 50(2)^x</div>
                        <div class="section-header">Explain what each part means:</div>
                        <div>What does 50 represent?</div>
                        <div class="response-line"></div>
                        <div>What does 2 represent?</div>
                        <div class="response-line"></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">Example 2: Vendor A Popcorn</div>
                        <table class="mini-table">
                            <tr>
                                <th>Bags</th>
                                <th>Cost</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>$3.00</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>$4.00</td>
                                <td>+$1.00</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>$5.00</td>
                                <td>+$1.00</td>
                            </tr>
                        </table>
                        <div class="section-header">Symbolic Rule:</div>
                        <div class="formula-box">y = 1x + 2</div>
                    </td>
                    <td>
                        <div class="section-header">Example 2: Vendor B Popcorn</div>
                        <table class="mini-table">
                            <tr>
                                <th>Bags</th>
                                <th>Cost</th>
                                <th>Growth?</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>$0.01</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>$0.03</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>$0.07</td>
                                <td>x2</td>
                            </tr>
                        </table>
                        <div class="section-header">Symbolic Rule:</div>
                        <div class="formula-box">y = 0.01(2^x - 1)</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="section-header">GENERIC SYMBOLIC FORM:</div>
                        <div class="formula-box">y = mx + b</div>
                        <div>m = constant rate</div>
                        <div>b = initial value</div>
                        <div class="section-header">Create your own linear example:</div>
                        <div class="writing-lines"></div>
                        <div class="section-header">Your symbolic rule:</div>
                        <div class="response-line"></div>
                    </td>
                    <td>
                        <div class="section-header">GENERIC SYMBOLIC FORM:</div>
                        <div class="formula-box">y = a(b)^x</div>
                        <div>a = initial value</div>
                        <div>b = multiplying factor</div>
                        <div class="section-header">Create your own exponential example:</div>
                        <div class="writing-lines"></div>
                        <div class="section-header">Your symbolic rule:</div>
                        <div class="response-line"></div>
                    </td>
                </tr>
            </table>
            
            <div style="margin-top: 20px;">
                <div class="section-header">Reflection: How can you tell if a function is linear or exponential?</div>
                <div class="writing-lines" style="min-height: 80px;"></div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_math_algebra_1_A1_FLQE_2_ = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>The Functional World of Linear and Exponential Ville</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        h2 {
            color: #233142;
            margin-top: 20px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .formula-box {
            font-size: 1em;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 15px 0;
        }
        th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
        }
        th {
            background-color: #233142;
            color: white;
        }
        .reflection-box {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .vocabulary, .fun-fact, .reflection-box {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>The Functional World of Linear and Exponential Ville</h1>
        <div class="subtitle">Welcome to Linear & Exponential Ville!</div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">Key Vocabulary:</div>
            <strong>Linear</strong> - grows by adding the same amount each time<br>
            <strong>Exponential</strong> - grows by multiplying by the same amount each time
        </div>
        
        <p>Lin and Ella live in a special town called Linear & Exponential Ville. Lin loves linear patterns, and Ella enjoys exponential patterns. They help people in their town by using math to solve problems.</p>
        
        <h2>Chapter 1: A Tale of Two Neighborhoods</h2>
        
        <p>One day, Mayor Powers calls Lin and Ella. He says, "West End grows by the same amount each year. East Hills grows by doubling its numbers every year. Can you help?"</p>
        
        <p>Lin visits West End first. Last year, West End had 350 people. Every year, 20 more people move there. Lin writes down a table:</p>
        
        <table>
            <tr>
                <th>Years Passed</th>
                <th>Population</th>
            </tr>
            <tr>
                <td>0</td>
                <td>350</td>
            </tr>
            <tr>
                <td>1</td>
                <td>370</td>
            </tr>
            <tr>
                <td>2</td>
                <td>390</td>
            </tr>
            <tr>
                <td>3</td>
                <td>410</td>
            </tr>
        </table>
        
        <p>Lin knows this is linear growth. He writes the rule for West End:</p>
        <p class="formula-box"><strong>y = 20x + 350</strong></p>
        
        <p style="margin-top: 15px">Ella visits East Hills. Mayor Powers said East Hills doubles each year. Last year, it had 50 people. Ella writes down a table:</p>
        
        <table>
            <tr>
                <th>Years Passed</th>
                <th>Population</th>
            </tr>
            <tr>
                <td>0</td>
                <td>50</td>
            </tr>
            <tr>
                <td>1</td>
                <td>100</td>
            </tr>
            <tr>
                <td>2</td>
                <td>200</td>
            </tr>
            <tr>
                <td>3</td>
                <td>400</td>
            </tr>
        </table>
        
        <p>Ella knows this is exponential growth. Her symbolic rule is:</p>
        <p class="formula-box"><strong>y = 50(2)^x</strong></p>
        
        <p style="margin-top: 15px">Lin and Ella show Mayor Powers their tables and rules. He says, "Great job! Now we can plan for our neighborhoods better!"</p>
        
        <h2>Chapter 2: Popcorn Pricing Problem at the Festival of Functions</h2>
        
        <p>The town has a big festival each year. Lin and Ella love popcorn from two different vendors.</p>
        
        <p>Vendor A charges a $2 fee plus $1 for each popcorn bag. Vendor B charges only one cent for the first bag, but doubles the price for each extra bag!</p>
        
        <p>Lin makes a table for Vendor A (linear):</p>
        
        <table>
            <tr>
                <th>Bags Bought</th>
                <th>Total Cost</th>
            </tr>
            <tr>
                <td>1</td>
                <td>$3.00</td>
            </tr>
            <tr>
                <td>2</td>
                <td>$4.00</td>
            </tr>
            <tr>
                <td>3</td>
                <td>$5.00</td>
            </tr>
            <tr>
                <td>4</td>
                <td>$6.00</td>
            </tr>
        </table>
        
        <p>The symbolic rule for Vendor A:</p>
        <p class="formula-box"><strong>y = 1x + 2</strong></p>
        
        <p style="margin-top: 15px">Ella makes a table for Vendor B (exponential):</p>
        
        <table>
            <tr>
                <th>Bags Bought</th>
                <th>Total Cost</th>
            </tr>
            <tr>
                <td>1</td>
                <td>$0.01</td>
            </tr>
            <tr>
                <td>2</td>
                <td>$0.03</td>
            </tr>
            <tr>
                <td>3</td>
                <td>$0.07</td>
            </tr>
            <tr>
                <td>4</td>
                <td>$0.15</td>
            </tr>
        </table>
        
        <p>The symbolic rule for Vendor B:</p>
        <p class="formula-box"><strong>y = 0.01(2^x - 1)</strong></p>
        
        <p style="margin-top: 15px">Ella says, "Wow! Vendor B starts cheap but gets expensive fast!"</p>
        
        <h2>Chapter 3: Art in Our Town</h2>
        
        <p>Mayor Powers asks Lin and Ella for help again. The town wants new art in two areas.</p>
        
        <p>Area 1 starts with 2 sculptures and adds exactly 3 sculptures each week. Lin sees this is linear growth and writes the rule:</p>
        <p class="formula-box"><strong>y = 3x + 2</strong></p>
        
        <p style="margin-top: 15px">Area 2 starts with 1 mural. Each week, the murals triple. Ella sees this exponential growth clearly:</p>
        <p class="formula-box"><strong>y = 1(3)^x</strong></p>
        
        <p style="margin-top: 15px">Mayor Powers smiles: "Thanks to your math skills, we know exactly how much art to expect in each area!"</p>
        
        <div class="reflection-box">
            <h3>Linear or Exponential?</h3>
            <p><strong>Linear:</strong> grows by adding the same number each time. (Like West End and Vendor A.)<br>
            Symbol: <span class="formula-box">y = mx + b</span></p>
            <p><strong>Exponential:</strong> grows by multiplying by the same number each time. (Like East Hills and Vendor B.)<br>
            Symbol: <span class="formula-box">y = a(b)^x</span></p>
            <p>Think about these differences to help solve other problems in Linear & Exponential Ville!</p>
        </div>
    </div>
</body>
</html>`;

export const teacherResource_math_algebra_1_A1_FLQE_2_ = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher Resource: The Functional World of Linear and Exponential Ville</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        h2 {
            color: #233142;
            margin-top: 20px;
            border-left: 4px solid #233142;
            padding-left: 10px;
        }
        h3 {
            color: #233142;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .objectives {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .implementation {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .extension {
            background-color: #fff8dc;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .assessment {
            background-color: #f8e5ff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .summary {
            background-color: #f0f0f0;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        ul, ol {
            padding-left: 20px;
        }
        li {
            margin-bottom: 8px;
        }
        .teacher-header {
            background-color: #233142;
            color: white;
            padding: 10px 15px;
            border-radius: 8px 8px 0 0;
            margin-bottom: 0;
        }
        .teacher-box {
            border: 2px solid #233142;
            border-radius: 8px;
            margin: 20px 0;
        }
        .teacher-content {
            padding: 15px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .objectives, .implementation, .extension, .assessment, .summary {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>TEACHER RESOURCE: The Functional World of Linear and Exponential Ville</h1>
        <div class="subtitle">Instructional Guide for Algebra 1</div>
        
        <div class="teacher-box">
            <h3 class="teacher-header">RESOURCE SUMMARY</h3>
            <div class="teacher-content">
                <p>This student resource presents linear and exponential functions through a narrative about two characters, Lin and Ella, who help solve problems in their town. Students explore real-world scenarios involving population growth, pricing models, and public art installations while learning to identify, differentiate, and create symbolic representations for linear and exponential functions.</p>
            </div>
        </div>

        <div class="objectives">
            <h2>Learning Objectives</h2>
            <ul>
                <li>Students will identify and differentiate between linear and exponential functions based on their growth patterns.</li>
                <li>Students will create symbolic representations (equations) for linear and exponential functions.</li>
                <li>Students will interpret realistic scenarios and translate them into tables, graphs, and equations aligned to standard A1.FLQE.2*.</li>
                <li>Students will compare and contrast linear and exponential growth patterns using real-world contexts.</li>
            </ul>
        </div>
        
        <div class="implementation">
            <h2>Implementation Strategies</h2>
            
            <h3>Before Reading</h3>
            <ul>
                <li>Activate prior knowledge by asking students to describe what they know about patterns that "grow by the same amount" versus patterns that "grow by the same factor."</li>
                <li>Preview key vocabulary terms "linear," "exponential," "growth," "double," and "triple" to ensure student comprehension.</li>
                <li>Consider having students make predictions about which growth pattern will result in larger values over time.</li>
            </ul>
            
            <h3>During Reading</h3>
            <ul>
                <li>Read each scenario aloud as a class, emphasizing key vocabulary terms.</li>
                <li>Pause after each chapter to check for understanding and clarify any misconceptions.</li>
                <li>Guide students through short discussions comparing the tables and symbolic rules. Ask: "What makes this linear or exponential?"</li>
                <li>Have students verify the rules by calculating values and checking against the provided tables.</li>
                <li>Encourage students to identify the initial value and growth rate/factor in each scenario.</li>
            </ul>
            
            <h3>After Reading</h3>
            <ul>
                <li>Use the Student Reflection Box as a quick formative assessment or as a group discussion starter.</li>
                <li>Encourage students to create additional examples similar to those given, reinforcing understanding of symbolic representations and growth patterns.</li>
                <li>Have students explain in their own words how to determine if a situation represents linear or exponential growth.</li>
            </ul>
        </div>
        
        <div class="assessment">
            <h2>Assessment Opportunities</h2>
            <ul>
                <li><strong>Formative Assessment:</strong> During class discussions, listen for students' ability to correctly identify growth patterns and explain their reasoning.</li>
                <li><strong>Exit Ticket:</strong> Present a new scenario and ask students to determine if it's linear or exponential and write the appropriate equation.</li>
                <li><strong>Group Work:</strong> Have students create and solve their own "Linear & Exponential Ville" scenarios in pairs.</li>
                <li><strong>Written Assessment:</strong> Ask students to compare and contrast the growth patterns in Chapter 1 and explain which neighborhood will have more people after 10 years.</li>
                <li><strong>Visual Representation:</strong> Have students graph the functions from any chapter and explain what the graph reveals about the growth pattern.</li>
            </ul>
        </div>
        
        <div class="extension">
            <h2>Extension Activities</h2>
            <ul>
                <li><strong>Graphing Comparison:</strong> Challenge students to graph these functions, visualize differences, and predict future values using their equations.</li>
                <li><strong>Crossover Point Analysis:</strong> Have students determine when Vendor B's popcorn becomes more expensive than Vendor A's by solving the equation.</li>
                <li><strong>Real World Research:</strong> Ask students to find and analyze real-world examples of linear and exponential growth (population, investments, technology adoption, etc.).</li>
                <li><strong>Story Continuation:</strong> Have students write a "Chapter 4" for Linear & Exponential Ville that includes both growth patterns.</li>
                <li><strong>Decay Scenarios:</strong> Extend learning by introducing exponential decay scenarios (half-life, depreciation) as a counterpart to growth.</li>
            </ul>
        </div>
        
        <div class="summary">
            <h2>Additional Teaching Notes</h2>
            <ul>
                <li><strong>Common Misconceptions:</strong> Students often confuse linear and exponential patterns. Emphasize that linear patterns add the same amount each time, while exponential patterns multiply by the same factor each time.</li>
                <li><strong>Visual Learners:</strong> Consider supplementing with visual representations showing the difference between linear and exponential growth using bar graphs or line plots.</li>
                <li><strong>Mathematical Precision:</strong> Ensure students understand that the symbolic rule y = a(b)^x requires b > 0 and b ≠ 1 for exponential functions.</li>
                <li><strong>Scaffolding:</strong> For struggling students, provide partially completed tables and guide them through identifying patterns before writing equations.</li>
                <li><strong>Connection to Standards:</strong> This activity directly supports standard A1.FLQE.2* which focuses on understanding and comparing linear and exponential functions.</li>
            </ul>
        </div>
        
        <div class="implementation">
            <h2>Differentiation Strategies</h2>
            
            <h3>For Advanced Students</h3>
            <ul>
                <li>Challenge students to create more complex scenarios involving both linear and exponential components.</li>
                <li>Introduce compound interest formulas as an extension of exponential growth.</li>
                <li>Have students explore recursive formulas for both types of functions.</li>
            </ul>
            
            <h3>For Struggling Students</h3>
            <ul>
                <li>Provide additional scaffolding through guided notes or partially completed tables.</li>
                <li>Use physical manipulatives or visual models to demonstrate growth patterns.</li>
                <li>Focus on identifying patterns in tables before moving to symbolic rules.</li>
                <li>Create anchor charts that clearly differentiate linear vs. exponential patterns.</li>
            </ul>
        </div>
    </div>
</body>
</html>`;

