import React from 'react'
import { Icon as AntdIcon, Progress } from 'antd'
//import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { Defs } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import TextToSpeech from './TextToSpeech';
let d3 = require('d3');
let MathJax = null
if (typeof window !== 'undefined') {
    MathJax = require('mathjax-react')
}

library.add(fas)

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addCommasToNumbersInString, addCommasToNumbersInStringLatex } from '../../Util';
import { min } from 'moment';

function isMathContent(str) {
    // The regex checks for numbers, basic math symbols, and spaces
    // You can expand the list inside the character class ([]) to allow more symbols if necessary.
    const mathRegex = /^[0-9+\-*/^%().\s]*$/;
    return mathRegex.test(str);
}

export class InlineMathText extends React.Component {
    state = {
        MathComponent: null
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            try {
                this.setState({ MathComponent: MathJax.MathComponent })
            } catch (error) {
                console.error(`Dynamic import failed: ${error}`)
            }
        }
    }

    render() {
        const { MathComponent } = this.state

        return (
            <div className={'inline-block' +
                (this.props.hasOwnProperty('className') && this.props.className ? ' ' + this.props.className : '')}>
                {MathComponent ?
                    <div className='force-all-inline'>
                        <MathComponent tex={this.props.text} />
                    </div>
                    :
                    <span>{this.props.text}</span>}
            </div>
        )
    }
}

export class BoldText extends React.Component {
    render() {
        let textContent = this.props.content
        let leadingSpace = ''
        let trailingSpace = ''
        if (this.props.hasOwnProperty('allComponents') &&
            this.props.allComponents &&
            this.props.hasOwnProperty('componentIdx')) {
            const allComponents = this.props.allComponents
            const componentIdx = this.props.componentIdx
            if (componentIdx - 1 >= 0) {
                const prevComponent = allComponents[componentIdx - 1]
                // add padding left if the previous component's last char is not a space
                if (prevComponent.hasOwnProperty('type') && prevComponent.type === 'Text') {
                    const text = prevComponent.content
                    if (text.length > 0 && text[text.length - 1] !== ' ') {
                        leadingSpace = ' '
                    }
                }
            }
            if (componentIdx + 1 < allComponents.length) {
                const nextComponent = allComponents[componentIdx + 1]
                if (nextComponent.hasOwnProperty('type') && nextComponent.type === 'Text') {
                    const text = nextComponent.content
                    if (text.length > 0 && text[0] !== ' ') {
                        trailingSpace = ' '
                    }
                }
            }
        }
        return <span>
            {leadingSpace ? <span>{leadingSpace}</span> : ''}
            <strong>{textContent}</strong>
            {trailingSpace ? <span>{trailingSpace}</span> : ''}
        </span>
    }
}

export class ItalicText extends React.Component {
    render() {
        return <em>{this.props.content}</em>
    }
}

class BlankText extends React.Component {
    render() {
        const { width } = this.props;

        // Generate a string with '_' repeated for the specified width
        const text = '_'.repeat(width);

        return (
            <span>{text}</span>
        );
    }
}

export class Text extends React.Component {
    render() {
        let addCommas = true
        // 12:05, 1:05, etc.
        const timeRegex = /\b\d{1,2}:\d{2}\b/;
        if ((this.props.hasOwnProperty('additionalData') &&
            this.props.additionalData &&
            this.props.additionalData.hasOwnProperty('addCommasToNumbers') &&
            !this.props.additionalData.addCommasToNumbers) ||
            (
                this.props.content &&
                (this.props.content.endsWith('a.m.') ||
                    this.props.content.endsWith('p.m.'))
            ) ||
            (
                // new time format without a.m. or p.m.
                timeRegex.test(this.props.content)
            )) {
            addCommas = false
        }
        return <span>{addCommas ? addCommasToNumbersInString(this.props.content) : this.props.content}</span>
    }
}

export class PassageText extends React.Component {
    render() {
        const paragraphs = this.props.content.split('\n\n').map((paragraph, index) => {
            return (
                <p className='mb-0' key={index} style={{ textIndent: '2em' }}>
                    {paragraph}
                </p>
            );
        });

        return <blockquote>{paragraphs}</blockquote>;
    }
}

export class P extends React.Component {
    render() {
        return <div>{this.props.content && this.props.content.map((contentComponent, idx) => {
            return renderComponent(contentComponent, this.props.componentKeyDict, this.props.additionalData, 'P', this.props.content, idx)
        })}</div>
    }
}

export class Break extends React.Component {
    render() {
        return <hr style={{ margin: '0.5cm 0' }} />
    }
}

export class H1 extends React.Component {
    render() {
        return <h1>{this.props.heading}</h1>
    }
}

export class H2 extends React.Component {
    render() {
        return <h2>{this.props.heading}</h2>
    }
}

export class H3 extends React.Component {
    render() {
        return <h3>{this.props.heading}</h3>
    }
}

export class Table extends React.Component {
    render() {
        const { data } = this.props
        return (
            <table>
                <tbody>
                    {data.map((row, i) => (
                        <tr key={i}>
                            {row.map((cell, j) => (
                                <td key={j}>{renderComponent(cell, this.props.componentKeyDict, this.props.additionalData, 'Table', this.props.allComponents, this.props.componentIdx)}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}

export class BulletList extends React.Component {
    render() {
        const { items } = this.props
        return (
            <ul>
                {items.map((item, i) => (
                    <li key={i}>{renderComponent(item, this.props.componentKeyDict, this.props.additionalData, 'BulletList', this.props.allComponents, this.props.componentIdx)}</li>
                ))}
            </ul>
        )
    }
}

export class ItemList extends React.Component {
    render() {
        const { items } = this.props
        return (
            <ol>
                {items.map((item, i) => {
                    // this does not need to be rendered in an <ol>
                    if (item.hasOwnProperty('type') && item.type === 'LineBreak') {
                        return false
                    }
                    let styleObj = {}
                    // try to add to styleObj if possible
                    if (item.hasOwnProperty('content') && item.content && typeof item.content === 'string') {
                        const startsWithNumber = item.content.match(/^\d+\./)
                        if (startsWithNumber) {
                            styleObj = { listStyleType: 'none' }
                        }
                    }
                    return <li style={styleObj} key={i}>{renderComponent(item, this.props.componentKeyDict, this.props.additionalData, 'ItemList', this.props.allComponents, this.props.componentIdx)}</li>
                })}
            </ol>
        )
    }
}

export class Align extends React.Component {
    render() {
        const { equations } = this.props
        return (
            <div style={{ textAlign: 'center' }}>
                {equations.map((eq, i) => (
                    <p key={i}>{eq.content}</p>
                ))}
            </div>
        )
    }
}

export class RightTriangle extends React.Component {
    render() {
        const { sides } = this.props;
        let angleMarkers = ['', '', '']
        if (this.props.hasOwnProperty('angleMarkers') &&
            this.props.angleMarkers) {
            angleMarkers = this.props.angleMarkers
        }
        const height = 220; // Height of the SVG container
        const width = 340;  // Base of the SVG container
        const padding = 45; // Padding around the triangle

        // Adjust the positions if necessary
        const base = width - 2 * padding;
        const sideHeight = height - 2 * padding;

        const top = 8;
        const sideATextPadding = (sides[0].length - 1) * 7;

        return (
            <svg width={width} height={height}>
                {/* Triangle sides */}
                <line x1={padding} y1={height - padding} x2={padding + base} y2={height - padding} stroke="black" />
                <line x1={padding} y1={height - padding} x2={padding} y2={top} stroke="black" />
                <line x1={padding} y1={top} x2={padding + base} y2={height - padding} stroke="black" />

                {/* Points */}
                {angleMarkers[0] != '' ?
                    <text x={padding - 5} y={height - padding / 2} fill="black">∠C</text>
                    : ''}
                {angleMarkers[1] != '' ?
                    <text x={padding + base - 1} y={height - padding / 2} fill="black">∠A</text>
                    : ''}
                {angleMarkers[2] != '' ?
                    <text x={padding - 5} y={padding - 5} fill="black">∠B</text>
                    : ''}

                {/* Sides */}
                <text x={base / 2 + padding / 2} y={height - padding / 2} fill="black">{sides[1]}</text> {/* Side CB */}
                <text x={padding / 2 - sideATextPadding} y={(height / 2) - top} fill="black">{sides[0]}</text> {/* Side AC */}
                <text x={(2 * padding + base) / 2 - 2} y={(height - padding) / 2 - 8} fill="black">{sides[2]}</text> {/* Side BA */}

                {/* Right angle marker */}
                <path d={`M ${padding}, ${height - padding - 15} L ${padding + 15}, ${height - padding - 15} L ${padding + 15}, ${height - padding}`} stroke="black" fill="none" />
                <text x={padding + 18} y={height - padding - 8} fill="black">90°</text>
            </svg>
        );
    }
}

export class Polygon extends React.Component {
    // Note: this is a placeholder implementation.
    // Real implementation would require a library for drawing.
    render() {
        return <div>A polygon would be drawn here.</div>
    }
}

export class Icon extends React.Component {
    render() {
        const { name } = this.props
        // remove fa- if it exists
        const iconName = name && name.startsWith('fa-') ? name.slice(3) : name
        return (
            <FontAwesomeIcon icon={iconName} width={24} height={24} />
        );
    }
}

export class IconBunch extends React.Component {
    render() {
        const { name } = this.props
        // remove fa- if it exists
        const iconName = name && name.startsWith('fa-') ? name.slice(3) : name
        const icons = [];
        for (let i = 0; i < this.props.rows; i++) {
            const row = [];
            for (let j = 0; j < this.props.columns; j++) {
                row.push(<FontAwesomeIcon icon={iconName} key={`${i}-${j}`} width={24} height={24} />);
            }
            icons.push(<div key={i}>{row}</div>);
        }
        return <div>{icons}</div>;
    }
}

export class RotateBox extends React.Component {
    render() {
        const { angle, component } = this.props
        const rotateStyle = { transform: `rotate(${angle}deg)` }
        return (
            <div style={rotateStyle}>
                {renderComponent(component, this.props.componentKeyDict, this.props.additionalData, 'RotateBox', this.props.allComponents, this.props.componentIdx)}
            </div>
        )
    }
}

export class HorizontalBox extends React.Component {
    render() {
        const { components } = this.props
        return (
            <div style={{ display: 'flex', justifyContent: 'start' }}>
                {components.map((component, i) => (
                    <div key={i}>
                        {renderComponent(component, this.props.componentKeyDict, this.props.additionalData, 'HorizontalBox', this.props.allComponents, this.props.componentIdx)}
                    </div>
                ))}
            </div>
        )
    }
}

export class Answer extends React.Component {
    render() {
        const { id, correct, choice, reason } = this.props

        // id is before question sort occurred, so only use it
        // if no sort has occurred
        let choiceLetter = 'A'
        if (id === 1) {
            choiceLetter = 'B'
        } else if (id === 2) {
            choiceLetter = 'C'
        } else if (id === 3) {
            choiceLetter = 'D'
        }

        // answerIdx is after sort has occurred
        if (this.props.hasOwnProperty('additionalData') &&
            this.props.additionalData &&
            this.props.additionalData.hasOwnProperty('answerIdx')) {
            const answerIdx = this.props.additionalData.answerIdx
            if (answerIdx === 0) {
                choiceLetter = 'A'
            } else if (answerIdx === 1) {
                choiceLetter = 'B'
            } else if (answerIdx === 2) {
                choiceLetter = 'C'
            } else if (answerIdx === 3) {
                choiceLetter = 'D'
            }
        }

        // the chosen answer is incorrect
        let choseThisAnswer = false
        if (this.props.hasOwnProperty('additionalData') &&
            this.props.additionalData &&
            this.props.additionalData.hasOwnProperty('quiz') &&
            this.props.additionalData.quiz &&
            this.props.additionalData.hasOwnProperty('questionIdx') &&
            this.props.additionalData.hasOwnProperty('answerIdx')) {
            let questionIdx = this.props.additionalData.questionIdx
            let answerIdx = this.props.additionalData.answerIdx
            let quiz = this.props.additionalData.quiz
            if (quiz.quiz.chosenAnswers.hasOwnProperty(questionIdx)) {
                let chosenAnswer = quiz.quiz.chosenAnswers[questionIdx]
                choseThisAnswer = chosenAnswer === answerIdx
            }
        }

        let showAllReasons = this.props.hasOwnProperty('additionalData') &&
            this.props.additionalData &&
            this.props.additionalData.hasOwnProperty('showAllReasons') &&
            this.props.additionalData.showAllReasons

        let hasHideAllReasons = this.props.hasOwnProperty('additionalData') &&
            this.props.additionalData &&
            this.props.additionalData.hasOwnProperty('hideAllReasons')

        return (
            <div className='mb-1 font-20 page-break-inside-avoid'>
                <div className={'p-2 border br-4' + (correct ?
                    ' border-success bg-light-green' : '') + (choseThisAnswer && !correct ?
                        ' border-error bg-light-red' : '')}>
                    {choseThisAnswer ?
                        <div className='font-20 font-bold mb-2'>You selected this answer:</div>
                        : ''}
                    <div className='flex w-100'>
                        <div className='mr-2'>
                            <div className='flex w-100 p-mb-0'>
                                <span className='mr-05'>{choiceLetter}.</span>
                                <span>
                                    {renderComponent(choice, this.props.componentKeyDict, this.props.additionalData, 'Answer-Choice', this.props.allComponents, this.props.componentIdx)}
                                </span>
                            </div>
                        </div>
                        <div className='ml-auto'>
                            {correct ?
                                <AntdIcon type='check' className='text-success' />
                                : ''}
                            {choseThisAnswer && !correct ?
                                <AntdIcon type='close' className='text-danger' />
                                : ''}
                        </div>
                    </div>

                    {(hasHideAllReasons && this.props.additionalData.hideAllReasons) ?
                        '' :
                        (correct || choseThisAnswer || showAllReasons) ?
                            <div className='font-20 mt-2 pt-2 border-top'>
                                <div className='font-bold'>{'Why this answer is ' + (correct ? ' correct' : 'incorrect') + ':'}</div>
                                <div className='p-mb-0'>
                                    {renderComponent(reason, this.props.componentKeyDict, this.props.additionalData, 'Answer-Reason', this.props.allComponents, this.props.componentIdx)}
                                </div>
                            </div>
                            : ''}

                    {this.props.hasOwnProperty('additionalData') &&
                        this.props.additionalData &&
                        this.props.additionalData.hasOwnProperty('questionStats') &&
                        this.props.additionalData.questionStats &&
                        this.props.additionalData.questionStats.hasOwnProperty('chosen_answers') &&
                        this.props.additionalData.questionStats.chosen_answers &&
                        this.props.additionalData.questionStats.chosen_answers.hasOwnProperty(id) ?
                        <div className='font-20 mt-2 pt-2 border-top'>
                            <div>
                                {this.props.additionalData.questionStats.chosen_answers[id]} {this.props.additionalData.questionStats.chosen_answers[id] === 1 ? 'student' : 'students'} chose this answer.
                            </div>
                            <div className='pr-12px'>
                                <Progress percent={
                                    this.props.additionalData.questionStats.total === 0 ?
                                        0 :
                                        ((this.props.additionalData.questionStats.chosen_answers[id] /
                                            this.props.additionalData.questionStats.total) * 100).toFixed(2)
                                } />
                            </div>
                        </div>
                        : ''}

                </div>
            </div>
        )
    }
}

export class EnumList extends React.Component {
    renderItems() {
        return this.props.items.map((item, index) => {
            let styleObj = {}
            if (item.hasOwnProperty('content') && item.content) {
                const contentType = typeof item.content
                if (contentType === 'string') {
                    const startsWithNumber = item.content.match(/^\d+\./)
                    if (startsWithNumber) {
                        styleObj = { listStyleType: 'none' }
                    }
                }
            }

            return <li style={styleObj}>{renderComponent(item, this.props.componentKeyDict, this.props.additionalData, 'EnumList', this.props.allComponents, this.props.componentIdx)}</li>
        })
    }

    render() {
        return <ol>{this.renderItems()}</ol>
    }
}

export class Assessment extends React.Component {
    renderQuestionComponents() {
        //const questionText = getTextFromDocument(this.props.question)
        // Assuming that the question components are valid React components or strings
        return <div>
            {/*<TextToSpeech text={questionText} />*/}
            {this.props.question.map((component, index) => {
                return renderComponent(component, this.props.componentKeyDict, this.props.additionalData, 'Assessment-Question', this.props.question, index)
            })}
        </div>
    }

    renderAnswerComponents() {
        // Assuming that the Answer components are valid React components
        return this.props.answers.map((answer, index) => {
            let additionalData = Object.assign({}, this.props.additionalData)
            additionalData.answerIdx = index
            additionalData.question = this.props.question
            return renderComponent(answer, this.props.componentKeyDict, additionalData, 'Assessment-Answer', this.props.answers, index)
        })
    }

    render() {
        return (
            <div>
                {this.renderQuestionComponents()}
                <div style={{ margin: '1em 0' }} />
                {this.renderAnswerComponents()}
            </div>
        )
    }
}

export class LineBreak extends React.Component {
    render() {
        return <div className='p-1 w-100'></div>
    }
}

export class UnderlineText extends React.Component {
    render() {
        let textContent = this.props.content
        let leadingSpace = ''
        let trailingSpace = ''
        if (this.props.hasOwnProperty('allComponents') &&
            this.props.allComponents &&
            this.props.hasOwnProperty('componentIdx')) {
            const allComponents = this.props.allComponents
            const componentIdx = this.props.componentIdx
            if (componentIdx - 1 >= 0) {
                const prevComponent = allComponents[componentIdx - 1]
                // add padding left if the previous component's last char is not a space
                if (prevComponent.hasOwnProperty('type') && prevComponent.type === 'Text') {
                    const text = prevComponent.content
                    if (text.length > 0 && text[text.length - 1] !== ' ') {
                        leadingSpace = ' '
                    }
                }
            }
            if (componentIdx + 1 < allComponents.length) {
                const nextComponent = allComponents[componentIdx + 1]
                if (nextComponent.hasOwnProperty('type') && nextComponent.type === 'Text') {
                    const text = nextComponent.content
                    if (text.length > 0 && text[0] !== ' ') {
                        trailingSpace = ' '
                    }
                }
            }
        }
        return <span>
            {leadingSpace ? <span>{leadingSpace}</span> : ''}
            <u className='inline'>{textContent}</u>
            {trailingSpace ? <span>{trailingSpace}</span> : ''}
        </span>
    }
}

export class Equation extends React.Component {
    render() {
        let paddingLeftClass = 'pl-1px-important'
        let paddingRightClass = 'pr-1px-important'
        if (this.props.hasOwnProperty('allComponents') &&
            this.props.allComponents &&
            this.props.hasOwnProperty('componentIdx') &&
            this.props.componentIdx) {
            const allComponents = this.props.allComponents
            const componentIdx = this.props.componentIdx
            if (componentIdx > 0) {
                const prevComponent = allComponents[componentIdx - 1]
                // add padding left if the previous component's last char is not a space
                if (prevComponent.hasOwnProperty('type') && prevComponent.type) {
                    if (prevComponent.type === 'Text') {
                        const textContent = prevComponent.content
                        let lastChar = ''
                        if (textContent && textContent.length > 0) {
                            lastChar = textContent[textContent.length - 1]
                        }

                        if (lastChar !== ' ') {
                            paddingLeftClass = 'pl-1'
                        }
                    } else if (prevComponent.type === 'BlankText') {
                        paddingLeftClass = 'pl-1'
                    }
                }
            }
            // add padding right if the next component's first char is not punctuation/space
            if (componentIdx < allComponents.length - 1) {
                const nextComponent = allComponents[componentIdx + 1]
                if (nextComponent.hasOwnProperty('type') && nextComponent.type) {
                    if (nextComponent.type === 'Text') {
                        const textContent = nextComponent.content
                        let firstChar = ''
                        if (textContent && textContent.length > 0) {
                            firstChar = textContent[0]
                        }
                        if (firstChar !== ' ' && firstChar !== ',' && firstChar !== '.' && firstChar !== '?' && firstChar !== '!' && firstChar !== ':') {
                            paddingRightClass = 'pr-1'
                        }
                    } else if (nextComponent.type === 'BlankText') {
                        paddingRightClass = 'pr-1'
                    }
                }
            }
        }
        return <span className={paddingLeftClass + ' ' + paddingRightClass}>
            <InlineMathText text={addCommasToNumbersInStringLatex(this.props.content)}></InlineMathText>
        </span>
    }
}

class IntNumberLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            svgWidth: 580,
        };
    }

    isInteger(value) {
        return Math.floor(value) === value;
    }

    renderTicks() {
        const { xmin, xmax, ticks, ticklabels } = this.props;
        if (!ticks) {
            return '';
        }
        const padding = 30;
        const svgWidth = this.state.svgWidth + padding * 2;
        const scale = (svgWidth - padding * 2) / (xmax - xmin);

        return ticks.map((tick, index) => {
            const isInt = this.isInteger(tick);
            const tickHeight = isInt ? 10 : 5;
            const y1 = 40 - tickHeight;
            const y2 = 40 + tickHeight;
            const tickTransform = `translate(${padding + (tick - xmin) * scale}, 0)`;

            return (
                <g key={index} transform={tickTransform}>
                    <line x1="0" y1={y1} x2="0" y2={y2} stroke="black" />
                    {/*<text x="0" y={isInt ? "70" : "60"} textAnchor="middle"
                        fontSize={isInt ? "16px" : "10px"}>{isInt ? ticklabels[index] : ''}</text>*/}
                    <foreignObject width="100" height="50" x="-50" y={isInt ? "50" : "52"}>
                        <div xmlns="http://www.w3.org/1999/xhtml" style={{ textAlign: 'center' }}>
                            <InlineMathText
                                className={isInt ? 'font-18 font-bold' : 'font-14'}
                                text={addCommasToNumbersInStringLatex(ticklabels[index])}>
                            </InlineMathText>
                        </div>
                    </foreignObject>
                </g>
            );
        });
    }

    renderPoints() {
        const { xmin, xmax, points, pointlabels } = this.props;
        const padding = 30;
        const svgWidth = this.state.svgWidth + padding * 2;
        const scale = (svgWidth - padding * 2) / (xmax - xmin);

        return points.map((point, index) => {
            let pointLabel = '';
            if (pointlabels && index >= 0 && index < pointlabels.length) {
                pointLabel = pointlabels[index];
            }
            return <g key={index} transform={`translate(${padding + (point - xmin) * scale}, 40)`}>
                <circle cx="0" cy="0" r="5" fill="black" />
                <text x="0" y="-10" textAnchor="middle">{pointLabel}</text>
            </g>
        });
    }

    render() {
        const { xmin, xmax } = this.props;
        const svgWidth = this.state.svgWidth + 30 * 2; // add padding to SVG width

        return (
            <div>
                <svg width={svgWidth} height="100">
                    <line x1="30" y1="40" x2={svgWidth - 30} y2="40" stroke="black" />
                    {this.renderTicks()}
                    {this.renderPoints()}
                </svg>
            </div>
        );
    }
}


export class Circle extends React.Component {
    render() {
        const { radius } = this.props;

        // Fixed visual dimensions
        const svgRadius = 110; // This means the diameter will be 120, same as the square's side
        const svgSize = svgRadius * 2;
        const padding = 20; // Adjust as needed for space around the circle
        // approximate width of the text
        const radiusTextWidth = (radius.toFixed(2).length + 3) * 8;

        return (
            <svg height={svgSize + padding * 2} width={svgSize + padding * 2}>
                <circle
                    cx={svgRadius + padding}
                    cy={svgRadius + padding}
                    r={svgRadius}
                    stroke="black"
                    strokeWidth="2"
                    fill="transparent"
                />
                <circle cx={svgRadius + padding} cy={svgRadius + padding} r="5" fill="black" />
                <line
                    x1={svgRadius + padding}
                    y1={svgRadius + padding}
                    x2={svgSize + padding}
                    y2={svgRadius + padding}
                    stroke="black"
                    strokeWidth="2"
                />
                <text
                    x={(svgSize + padding * 2) / 2 + (radiusTextWidth / 2) + 18}
                    y={svgRadius + padding - 12}
                    textAnchor="middle"
                    fontSize="20px"
                >
                    {'r = ' + radius.toFixed(2)}
                </text>
            </svg>
        );
    }
}


export class Rectangle extends React.Component {
    render() {
        const { width, height } = this.props;
        let widthLabel = '' + width.toFixed(2);
        let heightLabel = '' + height.toFixed(2);
        if (this.props.hasOwnProperty('width_label') && this.props.hasOwnProperty('height_label')) {
            widthLabel = this.props.width_label;
            heightLabel = this.props.height_label;
        }
        let renderedWidth = 120;  // Fixed visual width
        let renderedHeight = 80;  // Fixed visual height
        const padding = 20;

        if (height > width) {
            // If the rectangle is taller than it is wide, swap the dimensions
            // so that the rectangle is rendered in portrait orientation
            const temp = renderedWidth;
            renderedWidth = renderedHeight;
            renderedHeight = temp;
        }

        return (
            <svg height={renderedHeight + padding * 2 + 25} width={renderedWidth + padding * 2 + 50}>
                <rect
                    x={padding}
                    y={padding}
                    width={renderedWidth}
                    height={renderedHeight}
                    stroke="black"
                    strokeWidth="2"
                    fill="transparent"
                />
                <text
                    x={padding + renderedWidth / 2}
                    y={padding + renderedHeight + 24}
                    textAnchor="middle"
                    fontSize="20px"
                >
                    {widthLabel}
                </text>
                <text
                    x={padding * 2 + renderedWidth - 11}
                    y={padding + (renderedHeight / 2) + 5}
                    textAnchor="left"
                    fontSize="20px"
                >
                    {heightLabel}
                </text>
            </svg>
        );
    }
}


export class Square extends React.Component {
    render() {
        const { side } = this.props;

        // Fixed dimensions for the SVG
        const svgSide = 120;
        const padding = 0;

        // Compute the actual rendered side of the square based on the prop
        const renderedSide = 7 * 10;

        // Adjust positions to center the square in the SVG
        const xOffset = (svgSide - renderedSide) / 2;
        const yOffset = (svgSide - renderedSide) / 2;

        return (
            <svg height={svgSide + padding * 2} width={svgSide + padding * 2}>
                <rect
                    x={xOffset + padding}
                    y={5}
                    width={renderedSide}
                    height={renderedSide}
                    stroke="black"
                    strokeWidth="2"
                    fill="transparent"
                />
                <text
                    x={padding + svgSide / 2}
                    y={renderedSide + padding * 2 + 28}
                    textAnchor="middle"
                    fontSize="20px"
                >
                    {side.toFixed(2)}
                </text>
            </svg>
        );
    }
}


export class Triangle extends React.Component {
    render() {
        const { base, height } = this.props;
        const svgBase = base * 10;
        const svgHeight = height * 10;
        const padding = 20;

        return (
            <svg height={svgHeight + padding * 2} width={svgBase + padding * 2}>
                <polygon
                    points={`${padding}, ${svgHeight + padding} ${svgBase + padding}, ${svgHeight + padding} ${svgBase / 2 + padding}, ${padding}`}
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                />
                <line
                    x1={svgBase / 2 + padding}
                    y1={svgHeight + padding}
                    x2={svgBase / 2 + padding}
                    y2={padding}
                    stroke="black"
                    strokeWidth="3"
                />
                <text
                    x={padding + svgBase / 2}
                    y={svgHeight + padding + 20}
                    textAnchor="middle"
                    fontSize="20px"
                >
                    {base}
                </text>
                <text
                    x={svgBase / 2 + padding + 18}
                    y={svgHeight / 2 + padding}
                    textAnchor="middle"
                    fontSize="20px"
                >
                    {height}
                </text>
            </svg>
        );
    }
}

export class Ellipse extends React.Component {
    render() {
        const { major_axis, minor_axis } = this.props;
        const svgMajorAxis = major_axis * 10;
        const svgMinorAxis = minor_axis * 10;
        const padding = 30;

        return (
            <svg width={svgMajorAxis + padding * 2} height={svgMinorAxis + padding * 2}>
                <ellipse
                    cx={svgMajorAxis / 2 + padding}
                    cy={svgMinorAxis / 2 + padding}
                    rx={svgMajorAxis / 2}
                    ry={svgMinorAxis / 2}
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                />
                <line
                    x1={padding}
                    y1={svgMinorAxis / 2 + padding}
                    x2={svgMajorAxis + padding}
                    y2={svgMinorAxis / 2 + padding}
                    stroke="black"
                    strokeWidth="3"
                />
                <line
                    x1={svgMajorAxis / 2 + padding}
                    y1={padding}
                    x2={svgMajorAxis / 2 + padding}
                    y2={svgMinorAxis + padding}
                    stroke="black"
                    strokeWidth="3"
                />
                <text
                    x={svgMajorAxis + padding + 12}
                    y={svgMinorAxis / 2 + padding + 7}
                    textAnchor="middle"
                    fontSize="20px"
                >
                    {major_axis}
                </text>
                <text
                    x={svgMajorAxis / 2 + padding}
                    y={svgMinorAxis + padding * 2 - 5}
                    textAnchor="middle"
                    fontSize="20px"
                >
                    {minor_axis}
                </text>
            </svg>
        );
    }
}

export class RegularPolygon extends React.Component {
    calculatePolygonPoints(sides) {
        const radius = 50
        let points = '';

        for (let i = 0; i < sides; i++) {
            const angle = (i * 2 * Math.PI) / sides;
            const x = 50 + radius * Math.cos(angle);
            const y = 50 + radius * Math.sin(angle);
            points += `${x},${y} `;
        }

        return points.trim();
    }

    render() {
        const { sides, side_length } = this.props;
        const points = this.calculatePolygonPoints(sides);
        const strokeWidth = 3
        const radiusLineEndPoint = points.split(" ")[0];

        return (
            <svg viewBox={`${-strokeWidth} ${-strokeWidth} ${100 + 2 * strokeWidth} ${100 + 2 * strokeWidth}`} style={{ width: '100px', height: '100px' }}>
                <polygon points={points} stroke="black" fill="none" strokeWidth={strokeWidth} />
                <line x1="50" y1="50" x2={radiusLineEndPoint.split(",")[0]} y2={radiusLineEndPoint.split(",")[1]} stroke="black" strokeWidth={side_length} />
                <text x="50" y="50" dx="0" dy="-10" fontSize="20" fill="black">{side_length}</text>
            </svg>
        );
    }
}

export class Trapezoid extends React.Component {
    render() {
        const { base1, base2, height } = this.props;
        const longBase = Math.max(base1, base2);
        const shortBase = Math.min(base1, base2);
        const upperBaseX = (longBase - shortBase) / 2;
        const points = `0,${height} ${upperBaseX},0 ${longBase - upperBaseX},0 ${longBase},${height}`;

        const strokeWidth = (longBase * 3.8) / 100;  // stroke width is 1% of viewBox width
        const padding = longBase * 0.1;  // use 10% of longBase as padding

        return (
            <svg viewBox={`0 0 ${longBase + 2 * padding} ${height + 2 * padding}`} style={{ width: '140px', height: '140px' }}>
                <polygon points={`${padding},${height + padding} ${upperBaseX + padding},${padding} ${longBase - upperBaseX + padding},${padding} ${longBase + padding},${height + padding}`}
                    stroke="black" fill="none" strokeWidth={strokeWidth} />
                <text x="50%" y={height + 3 * padding} textAnchor="middle" fontSize="5%">{base1}</text>
                <text x="50%" y={strokeWidth} textAnchor="middle" fontSize="5%">{base2}</text>
                <text x={longBase + padding + strokeWidth} y="50%" textAnchor="middle" fontSize="5%">{height}</text>
            </svg>
        );
    }
}

export class Parallelogram extends React.Component {
    render() {
        const { base, side } = this.props;
        const height = Math.sqrt(side * side - (base / 2) * (base / 2));
        const points = `0,${height} ${base / 2},0 ${3 * base / 2},0 ${base},${height}`;

        const strokeWidth = (base * 6.8) / 100;  // stroke width is 1% of viewBox width
        const padding = base * 0.1;  // use 10% of base as padding

        return (
            <svg viewBox={`0 0 ${base * 2 + 2 * padding} ${height + 2 * padding}`} style={{ width: '170px', height: '170px' }}>
                <polygon points={`${padding},${height + padding} ${base / 2 + padding},${padding} ${3 * base / 2 + padding},${padding} ${base + padding},${height + padding}`}
                    stroke="black" fill="none" strokeWidth={strokeWidth} />
                <text x="30%" y={height + 4.5 * padding} textAnchor="middle" fontSize="11%">{base}</text>
                <text x={padding + strokeWidth} y="50%" textAnchor="middle" fontSize="11%">{side}</text>
            </svg>
        );
    }
}

export class Rhombus extends React.Component {
    render() {
        const { diagonal1, diagonal2 } = this.props;
        const points = `0,${diagonal2 / 2} ${diagonal1 / 2},0 ${diagonal1},${diagonal2 / 2} ${diagonal1 / 2},${diagonal2}`;

        const strokeWidth = (diagonal1 * 3.8) / 100;  // stroke width is 1% of viewBox width
        const padding = diagonal1 * 0.1;  // use 10% of diagonal1 as padding

        return (
            <svg viewBox={`0 0 ${diagonal1 + 2 * padding} ${diagonal2 + 2 * padding}`} style={{ width: '140px', height: '140px' }}>
                <polygon points={`0,${diagonal2 / 2 + padding} ${diagonal1 / 2 + padding},${padding} ${diagonal1 + padding},${diagonal2 / 2 + padding} ${diagonal1 / 2 + padding},${diagonal2 + padding}`}
                    stroke="black" fill="none" strokeWidth={strokeWidth} />
                <line x1={0} y1={diagonal2 / 2 + padding} x2={diagonal1 + padding} y2={diagonal2 / 2 + padding} stroke="black" strokeWidth={strokeWidth} />
                <line x1={diagonal1 / 2 + padding} y1={padding} x2={diagonal1 / 2 + padding} y2={diagonal2 + padding} stroke="black" strokeWidth={strokeWidth} />
                <text x="68%" y={'47%'} textAnchor="middle" fontSize="5%">{diagonal1}</text>
                <text x={'58%'} y="77%" textAnchor="middle" fontSize="5%">{diagonal2}</text>
            </svg>
        );
    }
}

export class CircleSector extends React.Component {
    calculateSectorPath(radius, angle) {
        // Polar to Cartesian conversion
        const endX = radius * Math.cos((-angle * Math.PI) / 180);
        const endY = radius * Math.sin((-angle * Math.PI) / 180);

        // largeArcFlag and sweepFlag determine the shape and direction of the arc
        const largeArcFlag = angle <= 180 ? '0' : '1';
        const sweepFlag = '0'; // Draws the arc in a clockwise direction

        // Create SVG path
        return `M 0,0 L ${radius},0 A ${radius},${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX},${endY} Z`;
    }

    render() {
        const { radius, angle } = this.props;
        const pathData = this.calculateSectorPath(radius, angle);
        const viewBoxSize = 2 * radius;
        const padding = radius * 0.2;  // Add 20% of the radius as padding

        return (
            <svg viewBox={`-${radius - padding} -${radius - padding} ${viewBoxSize + 2 * padding} ${viewBoxSize + 2 * padding}`} style={{ width: '200px', height: '200px' }}>
                <path d={pathData} stroke="black" fill="none" strokeWidth="0.27" />
                <text x="22%" y={radius / 2} textAnchor="middle" fontSize="10%">{radius}</text>
            </svg>
        );
    }
}

export class ScatterPlot extends React.Component {
    constructor(props) {
        super(props);
        this.tooltipRef = React.createRef();
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this.drawChart();
    }

    componentDidUpdate() {
        this.drawChart();
    }

    drawChart() {
        const node = this.chartRef.current;
        d3.select(node).selectAll('*').remove();

        const margin = { top: 60, right: 30, bottom: 68, left: 68 };
        const width = 500 - margin.left - margin.right;
        const height = 500 - margin.top - margin.bottom;

        let xDomainMin = Math.min(...this.props.x);
        let xDomainMax = Math.max(...this.props.x);
        if (xDomainMin === xDomainMax) {
            xDomainMin -= 1;
            xDomainMax += 1;
        }

        let yDomainMin = Math.min(...this.props.y);
        let yDomainMax = Math.max(...this.props.y);
        if (yDomainMin === yDomainMax) {
            yDomainMin -= 1;
            yDomainMax += 1;
        }

        const xScale = d3.scaleLinear()
            .domain([xDomainMin, xDomainMax])
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain([yDomainMin, yDomainMax])
            .range([height, 0]);

        const svg = d3.select(node)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        svg.append('text')
            .attr('x', width / 2)
            .attr('y', -20)
            .attr('text-anchor', 'middle')
            .style('font-size', '20px')
            .style('font-weight', 'bold')
            .text(this.props.title);

        const xAxisTicks = this.props.xticks ? this.props.xticks : xScale.ticks();
        const yAxisTicks = this.props.yticks ? this.props.yticks : yScale.ticks();

        // Add X-axis
        const xAxisGroup = svg.append('g')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale).tickValues(xAxisTicks).tickSizeOuter(0));
        xAxisGroup.selectAll('text')
            .style('font-size', '14px');

        // Add Y-axis
        const yAxisGroup = svg.append('g')
            .call(d3.axisLeft(yScale).tickValues(yAxisTicks).tickSizeOuter(0));
        yAxisGroup.selectAll('text')
            .style('font-size', '14px');

        // Add X-axis Label
        xAxisGroup.append('text')
            .attr('x', width / 2)
            .attr('y', 48)
            .style('text-anchor', 'middle')
            .style('fill', 'black')
            .style('font-size', '20px')
            .text(this.props.x_label);

        // Add Y-axis Label
        yAxisGroup.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('x', -height / 2)
            .attr('y', -45)
            .style('text-anchor', 'middle')
            .style('fill', 'black')
            .style('font-size', '20px')
            .text(this.props.y_label);

        // Add X-axis gridlines
        svg.append('g')
            .attr('transform', `translate(0, ${height})`)
            .attr('class', 'grid')
            .call(d3.axisBottom(xScale)
                .tickValues(xAxisTicks)
                .tickSize(-height)
                .tickFormat(() => '')
            )
            .selectAll('.tick line')
            .attr('stroke', '#ddd');

        // Add Y-axis gridlines
        svg.append('g')
            .attr('class', 'grid')
            .call(d3.axisLeft(yScale)
                .tickValues(yAxisTicks)
                .tickSize(-width)
                .tickFormat(() => '')
            )
            .selectAll('.tick line')
            .attr('stroke', '#ddd');

        const data = this.props.x.map((e, i) => {
            return {
                x: e,
                y: this.props.y[i],
                marker: this.props.markers ? this.props.markers[i] : null
            };
        });

        svg.selectAll('circle')
            .data(data)
            .enter().append('circle')
            .attr('cx', d => xScale(d.x))
            .attr('cy', d => yScale(d.y))
            .attr('r', 8)
            .attr('fill', '#1677ff')
            .on("mouseover", (event, idx) => {
                if (!(this.chartRef && this.chartRef.current)) {
                    return;
                }
                if (!(this.tooltipRef && this.tooltipRef.current)) {
                    return;
                }
                // if idx in bounds of this.props.x and this.props.y
                if (!(idx < this.props.x.length && idx < this.props.y.length)) {
                    return;
                }
                const parentRect = this.chartRef.current.getBoundingClientRect();
                const mouseX = d3.event.clientX - parentRect.left;
                const mouseY = d3.event.clientY - parentRect.top;
                const x = this.props.x[idx];
                const y = this.props.y[idx];
                const tooltip = this.tooltipRef.current;

                tooltip.style.visibility = "visible";
                tooltip.innerHTML = `X: ${x}<br>Y: ${y.toFixed(2)}`;
                tooltip.style.left = (mouseX + 10) + "px";
                tooltip.style.top = (mouseY - 10) + "px";
            })
            .on("mouseout", () => {
                const tooltip = this.tooltipRef.current;
                tooltip.style.visibility = "hidden";
            });


        if (this.props.markers) {
            const markersData = data.filter(d => d.marker !== null);
            svg.selectAll('.marker')
                .data(markersData)
                .enter().append('text')
                .attr('class', 'marker')
                .attr('x', d => xScale(d.x) + 10)
                .attr('y', d => yScale(d.y))
                .style('fill', 'black')
                .text(d => d.marker);
        }
    }

    render() {
        return <div className='relative'>
            <svg ref={this.chartRef}></svg>
            <div
                ref={this.tooltipRef}
                className='br-4'
                style={{
                    position: 'absolute',
                    zIndex: 10,
                    backgroundColor: 'white',
                    padding: '16px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
                    visibility: 'hidden',
                    fontSize: '20px',
                }}
            ></div>
        </div>
    }
}

export class LinePlot extends React.Component {
    determineXScale() {
        const isDate = isNaN(Date.parse(this.props.x[0])) === false;
        return isDate ?
            { type: 'point' } :
            { type: 'linear', min: 'auto', max: 'auto' };
    }

    render() {
        let data = [];
        let chartKey = '' + this.props.x.length + '-' + this.props.y.length;
        const shadeDirection = this.props.hasOwnProperty('shade_direction') && this.props.shade_direction ?
            this.props.shade_direction : 'none';
        let keyAdditions = 0;
        let startMinMaxValue = 9999999999;
        let minXValue = startMinMaxValue;
        let maxXValue = -1 * startMinMaxValue;
        let minYValue = startMinMaxValue;
        let maxYValue = -1 * startMinMaxValue;
        if (this.props.x.length === this.props.y.length) {
            let chartData = [];
            this.props.x.map((d, i) => {
                const x = Number(this.props.x[i])
                const y = Math.floor(this.props.y[i])
                chartData.push({
                    seriesName: 'chart values',

                    "x": x, // Ensure x values are numbers

                    "y": y,
                    color: "#1677ff"
                })
                if (keyAdditions < 5) {
                    chartKey += x + '-' + y;
                    keyAdditions += 1;
                }
                if (x < minXValue) {
                    minXValue = x;
                }
                if (x > maxXValue) {
                    maxXValue = x;
                }
                if (y < minYValue) {
                    minYValue = y;
                }
                if (y > maxYValue) {
                    maxYValue = y;
                }
            });
            chartData.sort((a, b) => a.x - b.x);

            data.push({
                id: 'chart values',
                color: "#1677ff",
                data: chartData
            });
        }

        let markers = [];
        if (!(minXValue > 0 || (minXValue < 0 && maxXValue < 0))) {
            markers.push(
                {
                    axis: 'x',
                    value: 0,
                    lineStyle: { stroke: '#777777', strokeWidth: 1 },
                },
            );
        }
        if (!(minYValue > 0 || (minYValue < 0 && maxYValue < 0))) {
            markers.push(
                {
                    axis: 'y',
                    value: 0,
                    lineStyle: { stroke: '#777777', strokeWidth: 1 },
                },
            );
        }

        const numberOfDataPoints = this.props.x.length;
        const tickValues = numberOfDataPoints > 15 ? this.props.x.filter((_, i) => i % Math.ceil(numberOfDataPoints / 10) === 0) : undefined;

        const AreaLayer = ({ series, xScale, yScale, innerHeight }) => {
            if (shadeDirection === 'none') {
                return null;
            }

            // right shade
            let areaData = series[0].data;

            let areaGenerator = d3.area()
                .x(d => xScale(d.data.x))
                .y0(innerHeight)
                .y1(d => yScale(d.data.y));

            // left shade
            if (shadeDirection === 'left') {
                // inverted (to the left of the line)
                // Extract the actual data points from the series object
                const actualData = series[0].data.map(item => item.data);

                // Define the start and end points for the area to ensure it starts from the top left
                const startDataPoint = { x: actualData[0].x, y: 0 }; // Top left point
                const endDataPoint = { x: actualData[actualData.length - 1].x, y: 0 }; // Top right point based on the last data point

                // Complete area data including the start and end points
                areaData = [startDataPoint, ...actualData, endDataPoint];

                // Area generator function
                areaGenerator = d3.area()
                    .x(d => xScale(d.x))
                    .y0(0)  // Start from the top of the chart
                    .y1(d => yScale(d.y)); // Down to the line
            }

            const areaPath = areaGenerator(areaData);

            return (
                <>
                    <path
                        d={areaPath}
                        fill="#1677ff"
                        fillOpacity={0.3}
                        stroke="none"
                    />
                </>
            );
        };

        let layers = ['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']
        if (shadeDirection !== 'none') {
            // add custom area layer
            layers = ['grid', 'markers', 'axes', 'areas', AreaLayer, 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']
        }

        return (
            <div style={{ width: '500px', height: '500px' }}
                key={chartKey}>
                <ResponsiveLine
                    data={data}
                    layers={layers}
                    margin={{ top: 60, right: 30, bottom: 70, left: 90 }}
                    xScale={this.determineXScale()}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        legend: this.props.x_label,
                        legendPosition: 'middle',
                        legendOffset: 46,
                        tickValues: tickValues
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: this.props.y_label,
                        legendPosition: 'middle',
                        legendOffset: -60,
                        format: (value) =>
                            new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value)
                    }}
                    pointSize={14}
                    pointBorderWidth={2}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    colors={['#1677ff']} // this sets the line color

                    theme={{
                        grid: {
                            line: {
                                //stroke: '', // Change grid line color
                                strokeWidth: 1,
                            },
                        },
                        axis: {
                            ticks: {
                                line: {
                                    //stroke: '', // Change axis ticks color
                                    strokeWidth: 1,
                                },
                                text: {
                                    //fill: '', // Change axis text color
                                    fontSize: 16,
                                },
                            },
                            legend: {
                                text: {
                                    //fill: '', // Change legend text color
                                    fontSize: 20,
                                },
                            },
                        },
                    }}
                    markers={markers}
                />
            </div>
        );
    }
}

class Clock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const hour = this.props.hour || 0;
        const minute = this.props.minute || 0;
        const second = this.props.second || 0;
        const width = 220;
        const height = 220;

        const centerX = width / 2;
        const centerY = height / 2;
        // Set the radius to be half of the smallest dimension minus a small margin
        const radius = Math.min(width, height) / 2 - 5;

        const hourPosition = (hour % 12) * 360 / 12 + minute * 360 / 60 / 12;
        const minutePosition = minute * 360 / 60;
        const secondPosition = second * 360 / 60;

        const hourMarks = Array.from({ length: 12 }).map((_, i) => {
            let margin = 18;
            if (i >= 10) {
                margin = 24;
            }
            const x = centerX + (radius - margin) * Math.sin((i + 1) * Math.PI / 6);
            const y = centerY - (radius - margin) * Math.cos((i + 1) * Math.PI / 6);
            return <text key={`hour${i}`} x={x} y={y} textAnchor="middle" dominantBaseline="middle" fontSize={`${radius / 6}px`}>{i + 1}</text>;
        });

        const minuteMarks = Array.from({ length: 60 }).map((_, i) => {
            const markLength = i % 5 === 0 ? 10 : 5; // Longer mark for every 5 minutes
            const x1 = centerX + (radius - markLength) * Math.sin(i * Math.PI / 30);
            const y1 = centerY - (radius - markLength) * Math.cos(i * Math.PI / 30);
            const x2 = centerX + radius * Math.sin(i * Math.PI / 30);
            const y2 = centerY - radius * Math.cos(i * Math.PI / 30);
            return <line key={`min${i}`} x1={x1} y1={y1} x2={x2} y2={y2} stroke={i % 5 === 0 ? "black" : "grey"} strokeWidth={i % 5 === 0 ? "2" : "1"} />;
        });

        return (
            <svg width={width} height={height}>
                <circle cx={centerX} cy={centerY} r={radius} fill="none" stroke="black" strokeWidth="2" />
                {hourMarks}
                {minuteMarks}
                <line x1={centerX} y1={centerY} x2={centerX + (radius - 40) * Math.sin(hourPosition * Math.PI / 180)} y2={centerY - (radius - 40) * Math.cos(hourPosition * Math.PI / 180)} stroke="black" strokeWidth="2" />
                <line x1={centerX} y1={centerY} x2={centerX + (radius - 20) * Math.sin(minutePosition * Math.PI / 180)} y2={centerY - (radius - 20) * Math.cos(minutePosition * Math.PI / 180)} stroke="black" strokeWidth="1" />
                <line x1={centerX} y1={centerY} x2={centerX + (radius - 15) * Math.sin(secondPosition * Math.PI / 180)} y2={centerY - (radius - 15) * Math.cos(secondPosition * Math.PI / 180)} stroke="red" strokeWidth="1" />
                <circle cx={centerX} cy={centerY} r="3" fill="black" />
            </svg>
        );
    }
}


class ShapePlot extends React.Component {
    componentDidMount() {
        this.drawPlot();
    }

    componentDidUpdate() {
        this.drawPlot();
    }

    drawPlot() {
        const node = this.node;
        const width = 600;
        const height = 400;
        const margin = { top: 50, right: 50, bottom: 50, left: 50 }; // Increased margin for clarity

        // Find the max absolute values from data
        const x_values = this.props.points.map(point => Math.abs(point[0]));
        const y_values = this.props.points.map(point => Math.abs(point[1]));

        const max_x = Math.ceil(Math.max(...x_values));
        const max_y = Math.ceil(Math.max(...y_values));

        // Scale for x and y
        const x = d3.scaleLinear()
            .domain([-max_x, max_x])
            .range([margin.left, width - margin.right]);

        const y = d3.scaleLinear()
            .domain([-max_y, max_y])
            .range([height - margin.bottom, margin.top]);

        d3.select(node).selectAll('*').remove();

        const svg = d3.select(node)
            .attr('width', width)
            .attr('height', height);

        // X and Y axes
        svg.append('g')
            .attr('transform', `translate(0, ${y(0)})`)
            .call(d3.axisBottom(x));

        svg.append('g')
            .attr('transform', `translate(${x(0)},0)`)
            .call(d3.axisLeft(y));

        // Drawing the shape
        svg.append('path')
            .datum(this.props.points)
            .attr('fill', 'rgba(204, 229, 223, 0.75)')
            .attr('stroke', '#69b3a2')
            .attr('stroke-width', 1.5)
            .attr('d', d3.line()
                .x(d => x(d[0]))
                .y(d => y(d[1]))
                .curve(d3.curveLinearClosed)
            );

        // Drawing the labels if provided
        if (this.props.labels) {
            this.props.points.forEach((point, i) => {
                svg.append('text')
                    .attr('x', x(point[0]))
                    .attr('y', y(point[1]))
                    .attr('dy', '-0.5em')
                    .attr('text-anchor', 'start')
                    .text(this.props.labels[i]);
            });
        }
    }

    render() {
        return <svg ref={node => this.node = node}></svg>;
    }
}

class ArithmeticOperation extends React.Component {
    render() {
        const { numerator, denominator, operator } = this.props;

        const maxDigitCount = Math.max(
            numerator.toString().length,
            denominator.toString().length
        );

        let denominatorNumSpaces = maxDigitCount - denominator.toString().length - 1;
        if (denominatorNumSpaces < 0) {
            denominatorNumSpaces = 0;
        }
        const numeratorPadding = ' '.repeat(maxDigitCount - numerator.toString().length);
        const denominatorPadding = ' '.repeat(denominatorNumSpaces);

        return (
            <div className='inline-block' style={{ textAlign: 'right' }}>
                <div>{numeratorPadding}{numerator}</div>
                <div>{denominatorPadding}{operator} {denominator}</div>
                <hr />
            </div>
        );
    }
}

const componentMapping = {
    'Text': Text,
    'BoldText': BoldText,
    'ItalicText': ItalicText,
    'BlankText': BlankText,
    'BlankLine': BlankText,
    'PassageText': PassageText,
    'P': P,
    'Break': Break,
    'SectionBreak': Break,
    'H1': H1,
    'H2': H2,
    'H3': H3,
    'table': Table,
    'Table': Table,
    'BulletList': BulletList,
    'ItemList': ItemList,
    'Align': Align,
    'RightTriangle': RightTriangle,
    'Polygon': Polygon,
    'Icon': Icon,
    'IconBunch': IconBunch,
    'RotateBox': RotateBox,
    'HorizontalBox': HorizontalBox,
    'Answer': Answer,
    'EnumList': EnumList,
    'Assessment': Assessment,
    'LineBreak': LineBreak,
    'UnderlineText': UnderlineText,
    'Equation': Equation,
    'NumberLine': IntNumberLine,
    'Circle': Circle,
    'Rectangle': Rectangle,
    'Square': Square,
    'Triangle': Triangle,
    'Ellipse': Ellipse,
    'RegularPolygon': RegularPolygon,
    'Trapezoid': Trapezoid,
    'Parallelogram': Parallelogram,
    'Rhombus': Rhombus,
    'CircleSector': CircleSector,
    'ScatterPlot': ScatterPlot,
    'LinePlot': LinePlot,
    'Clock': Clock,
    'ShapePlot': ShapePlot,
    'ArithmeticOperation': ArithmeticOperation,
}

const renderComponent = (componentData, componentKeyDict, additionalData, callContext, allComponents, componentIdx) => {
    if (Array.isArray(componentData)) {
        const arrayStr = 'Array'
        if (!componentKeyDict.hasOwnProperty(arrayStr)) {
            componentKeyDict[arrayStr] = 0
        } else {
            componentKeyDict[arrayStr] += 1
        }
        return <div>
            {componentData.map((component, i) => {
                return <span key={componentKeyDict[arrayStr] + '-' + i}>{renderComponent(component, componentKeyDict, additionalData, callContext, componentData, i)}</span>
            })}
        </div>
    } else if (typeof componentData === 'string') {
        return <span>{componentData}</span>
    }

    if (!componentMapping[componentData.type]) {
        console.error(`Unknown component type: ${componentData.type}`, componentData, 'callContext:', callContext)
        return
    }

    if (!componentKeyDict.hasOwnProperty(componentData.type)) {
        componentKeyDict[componentData.type] = 0
    } else {
        componentKeyDict[componentData.type] += 1
    }

    const Component = componentMapping[componentData.type]
    return <Component
        key={componentData.type + '-' + componentKeyDict[componentData.type]}
        componentKeyDict={componentKeyDict}
        additionalData={additionalData}
        allComponents={allComponents}
        componentIdx={componentIdx}
        {...componentData} />
}

export class Document extends React.Component {
    render() {
        const { components } = this.props
        let componentKeyDict = {}
        let additionalData = { addCommasToNumbers: true }
        if (this.props.hasOwnProperty('additionalData') && this.props.additionalData) {
            additionalData = Object.assign(additionalData, this.props.additionalData)
        }
        if (this.props.hasOwnProperty('questionStats') &&
            this.props.questionStats) {
            additionalData.questionStats = this.props.questionStats
        }
        if (this.props.hasOwnProperty('assessment') &&
            this.props.assessment) {
            if (this.props.assessment.hasOwnProperty('standardCategory') &&
                (this.props.assessment.standardCategory === 'Social Studies' ||
                    this.props.assessment.standardCategory === 'ELA')) {
                // don't add commas to numbers for social studies (dates like 1950 get converted to 1,950)
                additionalData.addCommasToNumbers = false
            } else if (this.props.assessment.hasOwnProperty('standard') &&
                this.props.assessment.standard &&
                this.props.assessment.standard.hasOwnProperty('standardCategory') &&
                (this.props.assessment.standard.standardCategory === 'Social Studies' ||
                    this.props.assessment.standard.standardCategory === 'ELA')) {
                // don't add commas to numbers for social studies (dates like 1950 get converted to 1,950)
                additionalData.addCommasToNumbers = false
            }
        }

        return (
            <div className='edviz-document'>
                {components && components.map((componentData, componentIdx) => {
                    return renderComponent(componentData, componentKeyDict, additionalData, 'Document', components, componentIdx)
                })}
            </div>
        )
    }
}

export function getTextFromDocument(doc) {
    let resultText = "";

    const docType = typeof doc;
    // if list
    if (Array.isArray(doc)) {
        for (let i = 0; i < doc.length; i++) {
            resultText += getTextFromDocument(doc[i]);
        }
    } else if (docType === 'object') {
        if (doc.type === 'Text') {
            resultText += doc.content;
        } else {
            console.log('getTextFromDocument - unhandled object type:', doc.type);
        }
    } else if (docType === 'string') {
        resultText += doc;
    } else if (docType === 'number') {
        resultText += doc.toString();
    }

    return resultText;
}