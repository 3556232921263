export const graphicOrganizerEmpty_ela_1_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Silly Syllable Circus Tent</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 28px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
            .section-box .practice-row:last-child {
    margin-bottom: 28px;
}
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        .example-row {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }
        .word-label {
            width: 100px;
            font-size: 20px;
            font-weight: bold;
            margin-right: 10px;
        }
        .syllable-boxes {
            display: flex;
            flex-grow: 1;
        }
        .syllable-box {
            border: 2px solid #233142;
            border-radius: 8px;
            padding: 8px;
            margin: 0 5px;
            width: 100%;
            height: 40px;
            display: flex;
            flex-direction: column;
            font-size: 20px;
        }
        .box-label {
            font-weight: bold;
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .syllable-input {
            width: 100%;
            padding: 5px;
            border: none;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 20px;
            text-align: center;
            background-color: #f9f9f9;
        }
        .example-content {
            font-size: 20px;
            text-align: center;
            font-weight: bold;
        }
        .practice-row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        .reflection-box {
            margin-top: 10px;
            text-align: center;
            font-size: 20px;
        }
        .reflection-options {
            margin-top: 10px;
            font-size: 20px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Silly Syllable Circus Tent</h1>
        <div class="subtitle">Clap and write each syllable of the words below.</div>
        
        <div class="section-box">
            <div class="section-title">Example</div>
            <div class="example-row">
                <div class="word-label">Benny</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <div class="example-content">Ben</div>
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <div class="example-content">ny</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section-box">
            <div class="section-title">Your Turn</div>
            
            <div class="practice-row">
                <div class="word-label">Bunny</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Daisy</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Monkey</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Popcorn</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Gina</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Giraffe</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Dances</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Happy</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Circus</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Syllable 1</div>
                        <input type="text" class="syllable-input">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">Syllable 2</div>
                        <input type="text" class="syllable-input">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section-box">
            <div class="section-title">Check Your Beats!</div>
            <div class="reflection-box">
                Did you clap two times for each word?
                <div class="reflection-options">
                    <label>
                        <input type="radio" name="reflection" value="yes"> Yes
                    </label>
                    <label style="margin-left: 30px;">
                        <input type="radio" name="reflection" value="no"> No
                    </label>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ela_1_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Silly Syllable Circus Tent</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 28px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        .example-row {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }
        .word-label {
            width: 100px;
            font-size: 20px;
            font-weight: bold;
            margin-right: 10px;
        }
        .syllable-boxes {
            display: flex;
            flex-grow: 1;
        }
        .syllable-box {
            border: 2px solid #233142;
            border-radius: 8px;
            padding: 8px;
            margin: 0 5px;
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 20px;
            height: 40px;
            overflow: hidden;
        }
        .box-label {
            font-weight: bold;
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .syllable-input {
            width: 100%;
            padding: 5px;
            border: none;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 20px;
            text-align: center;
            background-color: #f9f9f9;
            box-sizing: border-box;
        }
        .example-content {
            font-size: 20px;
            text-align: center;
            font-weight: bold;
        }
        .practice-row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        /* Remove margin from the last practice row */
        .practice-row:last-child {
            margin-bottom: 0;
        }
        .reflection-box {
            margin-top: 10px;
            text-align: center;
            font-size: 20px;
        }
        .reflection-options {
            margin-top: 10px;
            font-size: 20px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Silly Syllable Circus Tent</h1>
        <div class="subtitle">Clap and write each syllable of the words below.</div>
        
        <div class="section-box">
            <div class="section-title">Example</div>
            <div class="example-row">
                <div class="word-label">Benny</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Ben</div>
                        <div class="example-content">Ben</div>
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">ny</div>
                        <div class="example-content">ny</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section-box">
            <div class="section-title">Your Turn</div>
            
            <div class="practice-row">
                <div class="word-label">Bunny</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Bun</div>
                        <input type="text" class="syllable-input" value="Bun">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">ny</div>
                        <input type="text" class="syllable-input" value="ny">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Daisy</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Dai</div>
                        <input type="text" class="syllable-input" value="Dai">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">sy</div>
                        <input type="text" class="syllable-input" value="sy">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Monkey</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Mon</div>
                        <input type="text" class="syllable-input" value="Mon">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">key</div>
                        <input type="text" class="syllable-input" value="key">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Popcorn</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Pop</div>
                        <input type="text" class="syllable-input" value="Pop">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">corn</div>
                        <input type="text" class="syllable-input" value="corn">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Gina</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Gi</div>
                        <input type="text" class="syllable-input" value="Gi">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">na</div>
                        <input type="text" class="syllable-input" value="na">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Giraffe</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Gi</div>
                        <input type="text" class="syllable-input" value="Gi">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">raffe</div>
                        <input type="text" class="syllable-input" value="raffe">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Dances</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Dan</div>
                        <input type="text" class="syllable-input" value="Dan">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">ces</div>
                        <input type="text" class="syllable-input" value="ces">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Happy</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Hap</div>
                        <input type="text" class="syllable-input" value="Hap">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">py</div>
                        <input type="text" class="syllable-input" value="py">
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Circus</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="box-label">Cir</div>
                        <input type="text" class="syllable-input" value="Cir">
                    </div>
                    <div class="syllable-box">
                        <div class="box-label">cus</div>
                        <input type="text" class="syllable-input" value="cus">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section-box">
            <div class="section-title">Check Your Beats!</div>
            <div class="reflection-box">
                Did you clap two times for each word?
                <div class="reflection-options">
                    <label>
                        <input type="radio" name="reflection" value="yes" checked> Yes
                    </label>
                    <label style="margin-left: 30px;">
                        <input type="radio" name="reflection" value="no"> No
                    </label>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ela_1_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Silly Syllable Circus Tent</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 28px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        .example-row {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }
        .word-label {
            width: 100px;
            font-size: 20px;
            font-weight: bold;
            margin-right: 10px;
        }
        .syllable-boxes {
            display: flex;
            flex-grow: 1;
        }
        .syllable-box {
            border: 2px solid #233142;
            border-radius: 8px;
            padding: 8px;
            margin: 0 5px;
            width: 100%;
            height: 40px;
            display: flex;
            flex-direction: column;
            font-size: 20px;
        }
        .box-label {
            font-weight: bold;
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .example-content {
            font-size: 20px;
            text-align: center;
            font-weight: bold;
        }
        .practice-row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        /* Remove margin from the last practice row */
        .practice-row:last-child {
            margin-bottom: 0;
        }
        .writing-box {
            border: 2px solid #233142;
            border-radius: 8px;
            padding: 8px;
            margin: 0 5px;
            width: 100%;
            height: 40px;
            display: flex;
            flex-direction: column;
            font-size: 20px;
            background-color: #f9f9f9;
        }
        .writing-space {
            height: 30px;
            width: 100%;
        }
        .reflection-box {
            margin-top: 10px;
            text-align: center;
            font-size: 20px;
        }
        .reflection-options {
            margin-top: 10px;
            font-size: 20px;
        }
        .circle-option {
            display: inline-block;
            border: 2px solid #233142;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            vertical-align: middle;
        }
        
        /* Print styles */
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Silly Syllable Circus Tent</h1>
        <div class="subtitle">Clap and write each syllable of the words below.</div>
        
        <div class="section-box">
            <div class="section-title">Example</div>
            <div class="example-row">
                <div class="word-label">Benny</div>
                <div class="syllable-boxes">
                    <div class="syllable-box">
                        <div class="example-content">Ben</div>
                    </div>
                    <div class="syllable-box">
                        <div class="example-content">ny</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section-box">
            <div class="section-title">Your Turn</div>
            
            <div class="practice-row">
                <div class="word-label">Bunny</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Daisy</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Monkey</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Popcorn</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Gina</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Giraffe</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Dances</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Happy</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
            
            <div class="practice-row">
                <div class="word-label">Circus</div>
                <div class="syllable-boxes">
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                    <div class="writing-box">
                        <div class="writing-space"></div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section-box">
            <div class="section-title">Check Your Beats!</div>
            <div class="reflection-box">
                Did you clap two times for each word?
                <div class="reflection-options">
                    Circle:
                    <span style="margin-left: 10px;">Yes</span>
                    <span class="circle-option"></span>
                    <span style="margin-left: 30px;">No</span>
                    <span class="circle-option"></span>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_ela_1_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Silly Syllable Circus</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
            font-size: 28px;
        }
        p {
            line-height: 1.8;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 22px;
        }
        .fun-fact p {
            font-size: 20px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Silly Syllable Circus!</h1>
        
        <p>I see Benny Bunny hop.</p>
        
        <p>Daisy Duck can jump high!</p>
        
        <p>Marco Monkey eats popcorn.</p>
        
        <p>Gina Giraffe dances happy.</p>
        
        <p>We all love the circus!</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Circus Fun!</div>
            <p>Clap for each word part as you read!</p>
        </div>
    </div>
</body>
</html>`;

export const teacherResource_ela_1_f_3_7 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher Guide: Silly Syllable Circus</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        header {
            background-color: #233142;
            color: white;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            text-align: center;
        }
        h1 {
            margin: 0;
            font-size: 24px;
        }
        h2 {
            color: #233142;
            border-bottom: 2px solid #233142;
            padding-bottom: 5px;
            margin-top: 30px;
        }
        h3 {
            color: #233142;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .objectives {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .discussion {
            background-color: #fff8dc;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .standards {
            background-color: #f8e5ff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #9c27b0;
        }
        ul, ol {
            margin-top: 5px;
            padding-left: 25px;
        }
        li {
            margin-bottom: 8px;
        }
        .summary {
            background-color: #f0f0f0;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            header {
                background-color: #eee;
                color: black;
            }
            .objectives, .instructions, .discussion, .standards {
                border: 1px solid #ccc;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <header>
            <h1>TEACHER GUIDE: Silly Syllable Circus</h1>
            <p>First Grade ELA - Syllable Segmentation Resource</p>
        </header>
        
        <div class="summary">
            <h3>Resource Summary</h3>
            <p>This student resource features a simple, engaging reading passage about circus animals with names and actions containing two-syllable words. The text includes five short sentences with multiple two-syllable words for students to practice reading and segmenting.</p>
        </div>
        
        <div class="objectives">
            <h3>Learning Objectives:</h3>
            <ul>
                <li>Students will practice reading and orally segmenting two-syllable words in context ("Benny," "Bunny," "Daisy," "Monkey," "popcorn," "Gina," "Giraffe," "dances," "happy," "circus").</li>
                <li>Students gain fluency and confidence decoding two-syllable words.</li>
            </ul>
        </div>
        
        <div class="instructions">
            <h3>Teaching Instructions:</h3>
            <ol>
                <li>Read aloud slowly, modeling how to clap or tap each syllable clearly. (Example: "Ben-ny," "Bun-ny," "pop-corn.")</li>
                <li>Guide students to repeat and clap/tap along.</li>
                <li>After reading, revisit each sentence. Have students locate and segment two-syllable words again independently or in pairs.</li>
            </ol>
        </div>
        
        <div class="discussion">
            <h3>Discussion Questions:</h3>
            <ul>
                <li>Which word has two beats (syllables)? Let's clap it.</li>
                <li>Can you find two animals with two syllables in their names?</li>
                <li>What other circus words can you think of?</li>
            </ul>
        </div>
        
        <h2>Implementation Suggestions</h2>
        
        <h3>Whole Group Activity</h3>
        <p>Project the passage on a board and read it chorally with students. Point to each word as you read, pausing to clap out syllables together for the two-syllable words.</p>
        
        <h3>Small Group Activity</h3>
        <p>Have students work in pairs to read the passage to each other. One student reads while the other claps the syllables, then switch roles.</p>
        
        <h3>Independent Practice</h3>
        <p>Provide students with individual copies and colored pencils or highlighters. Have them mark or highlight the two-syllable words they can identify.</p>
        
        <h3>Extension Activities</h3>
        <p>Have students create their own circus animal characters with two-syllable names and actions. They can draw pictures and write simple sentences following the pattern in the passage.</p>
        
        <h2>Assessment Opportunities</h2>
        
        <h3>Formative Assessment</h3>
        <p>As students clap out syllables, observe their accuracy. Note which students need additional support with particular words or syllable patterns.</p>
        
        <h3>Follow-up Assessment</h3>
        <p>Create a simple worksheet with two-syllable words from the passage (and a few new ones). Have students draw lines to divide the words into syllables.</p>
        
        <div class="standards">
            <h3>Standards Alignment:</h3>
            <p>This resource aligns directly to SC Standard ELA.1.F.3.7, engaging students in interactive, repetitive practice with age-appropriate vocabulary and simple sentences, supporting their decoding skills in a fun and memorable way.</p>
        </div>
        
        <h2>Differentiation Options</h2>
        
        <h3>Support</h3>
        <p>For students who need additional help, pre-teach the two-syllable words before reading the passage. Create word cards with the syllables divided by a visible line.</p>
        
        <h3>Challenge</h3>
        <p>Ask more advanced students to identify three-syllable words related to the circus theme (elephant, acrobat, wonderful) and create their own sentences using these words.</p>
    </div>
</body>
</html>`;