export const graphicOrganizerEmpty_ela_k_f_3_8_c = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>CVC Word Family Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 20px;
            font-size: 32px;
        }
        .simple-instructions {
            text-align: center;
            font-size: 24px;
            margin-bottom: 30px;
            color: #233142;
        }
        .word-families-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
        }
        .word-family-box {
            flex: 1;
            min-width: 200px;
            border: 4px solid #233142;
            border-radius: 15px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .family-title {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
            color: #233142;
        }
        .example-word {
            font-size: 28px;
            margin-bottom: 10px;
            text-align: center;
        }
        .your-words-label {
            font-size: 24px;
            margin: 20px 0 10px 0;
            font-weight: bold;
            color: #233142;
        }
        .word-input {
            width: 90%;
            padding: 10px;
            margin: 10px 0;
            border: 2px solid #aaa;
            border-radius: 10px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 24px;
            text-align: center;
        }
        .word-input:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 5px rgba(35, 49, 66, 0.5);
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>CVC Word Family Map</h1>
        <div class="simple-instructions">Read each word. Write new words by changing the first letter.</div>
        
        <div class="word-families-container">

            
            <!-- -at Family Box -->
            <div class="word-family-box">
                <div class="family-title">-at Family</div>
                <div class="example-word">cat</div>
                <div class="example-word">hat</div>
                
                <div class="your-words-label">Your Words:</div>
                <input type="text" class="word-input" aria-label="Your word 1">
                <input type="text" class="word-input" aria-label="Your word 2">
                <input type="text" class="word-input" aria-label="Your word 3">
            </div>
            
            <!-- -ap Family Box -->
            <div class="word-family-box">
                <div class="family-title">-ap Family</div>
                <div class="example-word">tap</div>
                
                <div class="your-words-label" style="margin-top: 72px">Your Words:</div>
                <input type="text" class="word-input" aria-label="Your word 1">
                <input type="text" class="word-input" aria-label="Your word 2">
                <input type="text" class="word-input" aria-label="Your word 3">
            </div>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ela_k_f_3_8_c = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>CVC Word Family Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 20px;
            font-size: 32px;
        }
        .simple-instructions {
            text-align: center;
            font-size: 24px;
            margin-bottom: 30px;
            color: #233142;
        }
        .word-families-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
        }
        .word-family-box {
            flex: 1;
            min-width: 200px;
            border: 4px solid #233142;
            border-radius: 15px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .family-title {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
            color: #233142;
        }
        .example-word {
            font-size: 28px;
            margin-bottom: 10px;
            text-align: center;
        }
        .your-words-label {
            font-size: 24px;
            margin: 20px 0 10px 0;
            font-weight: bold;
            color: #233142;
        }
        .word-input {
            width: 90%;
            padding: 10px;
            margin: 10px 0;
            border: 2px solid #aaa;
            border-radius: 10px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 24px;
            text-align: center;
        }
        .word-input:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 5px rgba(35, 49, 66, 0.5);
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>CVC Word Family Map</h1>
        <div class="simple-instructions">Read each word. Write new words by changing the first letter.</div>
        
        <div class="word-families-container">
            
            <!-- -at Family Box -->
            <div class="word-family-box">
                <div class="family-title">-at Family</div>
                <div class="example-word">cat</div>
                <div class="example-word">hat</div>
                
                <div class="your-words-label">Your Words:</div>
                <input type="text" class="word-input" aria-label="Your word 1" value="bat">
                <input type="text" class="word-input" aria-label="Your word 2" value="rat">
                <input type="text" class="word-input" aria-label="Your word 3" value="sat">
            </div>
            
            <!-- -ap Family Box -->
            <div class="word-family-box">
                <div class="family-title">-ap Family</div>
                <div class="example-word">tap</div>
                
                <div class="your-words-label" style="margin-top: 72px">Your Words:</div>
                <input type="text" class="word-input" aria-label="Your word 1" value="cap">
                <input type="text" class="word-input" aria-label="Your word 2" value="map">
                <input type="text" class="word-input" aria-label="Your word 3" value="nap">
            </div>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ela_k_f_3_8_c = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>CVC Word Family Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 20px;
            font-size: 32px;
        }
        .simple-instructions {
            text-align: center;
            font-size: 24px;
            margin-bottom: 30px;
            color: #233142;
        }
        .word-families-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
        }
        .word-family-box {
            flex: 1;
            min-width: 200px;
            border: 4px solid #233142;
            border-radius: 15px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .family-title {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
            color: #233142;
        }
        .example-word {
            font-size: 28px;
            margin-bottom: 10px;
            text-align: center;
        }
        .your-words-label {
            font-size: 24px;
            margin: 20px 0 10px 0;
            font-weight: bold;
            color: #233142;
        }
        .writing-line {
            width: 90%;
            height: 40px;
            border-bottom: 2px solid #aaa;
            margin: 15px 0;
            position: relative;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>CVC Word Family Map</h1>
        <div class="simple-instructions">Read each word. Write new words by changing the first letter.</div>
        
        <div class="word-families-container">
            
            <!-- -at Family Box -->
            <div class="word-family-box">
                <div class="family-title">-at Family</div>
                <div class="example-word">cat</div>
                <div class="example-word">hat</div>
                
                <div class="your-words-label">Your Words:</div>
                <div class="writing-line"></div>
                <div class="writing-line"></div>
                <div class="writing-line"></div>
            </div>
            
            <!-- -ap Family Box -->
            <div class="word-family-box">
                <div class="family-title">-ap Family</div>
                <div class="example-word">tap</div>
                
                <div class="your-words-label" style="margin-top: 72px">Your Words:</div>
                <div class="writing-line"></div>
                <div class="writing-line"></div>
                <div class="writing-line"></div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_ela_k_f_3_8_c = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>The CVC Explorers: Zac the Cat</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
            font-size: 36px;
        }
        p {
            line-height: 2.0;
            font-size: 32px;
            margin-bottom: 30px;
            text-align: center;
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
            font-size: 24px;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 28px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>The CVC Explorers: Zac the Cat</h1>
        
        <p>I see Zac.</p>
        
        <p>Zac is a cat.</p>
        
        <p>Zac has a hat.</p>
        
        <p>Zac can tap.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Word Family!</div>
            <p>cat, hat, bat, rat, sat</p>
        </div>
    </div>
</body>
</html>`;

export const teacherResource_ela_k_f_3_8_c = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher Guide: The CVC Explorers: Zac the Cat</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        h2 {
            color: #233142;
            margin-top: 25px;
        }
        h3 {
            color: #233142;
            margin-top: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .teacher-header {
            background-color: #233142;
            color: white;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 0;
            text-align: center;
        }
            .teacher-header h1 {
            color: white;
            margin-bottom: 0px;
            padding-bottom: 0px;
            }
        .objectives {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .discussion {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .guidance {
            background-color: #fff8dc;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .implementation {
            background-color: #f0e6ff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .assessment {
            background-color: #ffe6e6;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        ul, ol {
            padding-left: 25px;
        }
        li {
            margin-bottom: 8px;
        }
        .summary {
            background-color: #f5f5f5;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border-left: 4px solid #233142;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .objectives, .discussion, .guidance, .implementation, .assessment {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="teacher-header">
            <h1>TEACHER GUIDE ONLY</h1>
        </div>
        
        <h2>The CVC Explorers: Zac the Cat</h2>
        
        <div class="summary">
            <h3>Resource Summary</h3>
            <p>This student resource is a simple CVC (consonant-vowel-consonant) reading passage about a character named Zac the cat. The text consists of four short sentences using CVC words and includes a word family box highlighting words that rhyme with "cat".</p>
        </div>
        
        <div class="objectives">
            <h3>Learning Objectives</h3>
            <ul>
                <li>Students will decode and encode simple CVC words ("Zac," "cat," "hat," "tap").</li>
                <li>Students will practice blending individual phonemes to read simple words.</li>
                <li>Students will identify words within the same word family (e.g., -at words).</li>
            </ul>
        </div>
        
        <div class="discussion">
            <h3>Discussion Questions</h3>
            <ol>
                <li>What sound does "Z" make in "Zac"?</li>
                <li>Can you say the sounds in "cat"? (/k/ /æ/ /t/)</li>
                <li>What letters make the word "hat"?</li>
                <li>Can you think of other words that end with "-at" like cat and hat?</li>
                <li>What do you notice about the words in our Word Family box?</li>
            </ol>
        </div>
        
        <div class="guidance">
            <h3>Teaching Guidance</h3>
            <p>Slowly read each sentence aloud together with students. Use your finger to point clearly at each letter, helping students blend sounds. Encourage students to say each sound and then blend them to form CVC words. Reinforce decoding by having students identify other words with similar sounds (e.g., "hat," "cat").</p>
            
            <p>For struggling readers, you may want to:</p>
            <ul>
                <li>Use letter tiles or magnetic letters to build each word before reading</li>
                <li>Create a sound map where students can touch each sound as they say it</li>
                <li>Practice segmenting words into individual phonemes before blending</li>
            </ul>
            
            <p>For advanced readers, challenge them to:</p>
            <ul>
                <li>Write their own sentences using CVC words</li>
                <li>Find other words that rhyme with "tap"</li>
                <li>Create a new story about Zac the cat</li>
            </ul>
        </div>
        
        <div class="implementation">
            <h3>Implementation Suggestions</h3>
            <ul>
                <li><strong>Whole Group:</strong> Read the text together as a class, emphasizing the individual sounds in each CVC word. Use a pointer to track each word.</li>
                <li><strong>Small Group:</strong> Have students take turns reading one sentence at a time, focusing on proper blending techniques.</li>
                <li><strong>Centers:</strong> Place this text in a reading center with letter tiles so students can build the words after reading them.</li>
                <li><strong>Partner Reading:</strong> Have students read to a partner, with one student reading and the other checking for accuracy.</li>
                <li><strong>Word Building:</strong> After reading, have students use magnetic letters or letter cards to build the CVC words from the text.</li>
                <li><strong>Extension:</strong> Create a word sort activity using the word family words (cat, hat, bat, rat, sat) and other CVC words from different families.</li>
            </ul>
        </div>
        
        <div class="assessment">
            <h3>Assessment Opportunities</h3>
            <ul>
                <li><strong>Observation:</strong> Monitor students as they decode the CVC words, noting any patterns in errors.</li>
                <li><strong>Word Reading:</strong> Create flashcards with the CVC words from the text and assess students' ability to read them in isolation.</li>
                <li><strong>Word Writing:</strong> Dictate the CVC words for students to write, assessing their encoding skills.</li>
                <li><strong>Comprehension:</strong> Ask simple questions about Zac (What is Zac? What does Zac have? What can Zac do?).</li>
                <li><strong>Word Family:</strong> Have students generate additional words that belong in the -at word family.</li>
            </ul>
        </div>
        
        <div class="guidance">
            <h3>SC Standards Alignment</h3>
            <p>This resource aligns with the following South Carolina Kindergarten ELA standards:</p>
            <ul>
                <li>Reading Foundational Skills: Phonological Awareness</li>
                <li>Reading Foundational Skills: Phonics and Word Recognition</li>
                <li>Reading Foundational Skills: Fluency</li>
            </ul>
        </div>
    </div>
</body>
</html>`;