export const graphicOrganizerEmpty_math_8_8_F_4_d = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Linear Functions Graphic Organizer</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .section-box {
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .scenario-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 15px;
        }
        .scenario-table th {
            background-color: #233142;
            color: white;
            padding: 12px;
            text-align: left;
            border: 1px solid #ddd;
        }
        .scenario-table td {
            padding: 12px;
            border: 1px solid #ddd;
            vertical-align: top;
        }
        .scenario-description {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            margin-top: 10px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
            box-sizing: border-box;
        }
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .reflection-question {
            margin-bottom: 15px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Linear Functions: Connecting Math to Real Life</h1>
        <div class="subtitle">Interpreting Slope and Y-Intercept at Wildwood Middle School's "Courtside Café"</div>
        
        <div class="instructions">
            <p>Read each real-world scenario carefully. Then, examine the mathematical equation and the meanings of slope and y-intercept provided. Be ready to discuss how understanding these concepts helps make better business decisions.</p>
        </div>
        
        <form class="form-container">
            <div class="organizer-sheet">
                <div class="section-box">
                    <table class="scenario-table">
                        <thead>
                            <tr>
                                <th>Real-World Scenario</th>
                                <th>Mathematical Equation</th>
                                <th>Interpretation of Slope and Y-Intercept</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="scenario-description">Scenario 1: Cupcake Sales</div>
                                    <p>Students buy initial supplies (plates, napkins, cups) at a one-time cost of $30. It costs $1.50 per cupcake batch baked. Each cupcake batch sells for $6.</p>
                                </td>
                                <td>
                                    <div class="scenario-description">Profit Equation:</div>
                                    <p>Profit = 4.50x – 30</p>
                                    <p>(x = number of cupcake batches sold)</p>
                                </td>
                                <td>
                                    <div class="scenario-description">Slope (4.50):</div>
                                    <p>Each cupcake batch sold adds $4.50 to the profit.</p>
                                    <textarea class="text-area" placeholder="Add your own explanation of the slope..."></textarea>
                                    
                                    <div class="scenario-description" style="margin-top: 15px;">Y-Intercept (-30):</div>
                                    <p>The business begins with a loss of $30 due to initial supplies.</p>
                                    <textarea class="text-area" placeholder="Add your own explanation of the y-intercept..."></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="scenario-description">Scenario 2: Water Bottle Sales</div>
                                    <p>Students buy an initial cooler for $10. Each water bottle costs $0.50 to buy and sells for $1.25.</p>
                                </td>
                                <td>
                                    <div class="scenario-description">Profit Equation:</div>
                                    <p>Profit = 0.75x – 10</p>
                                    <p>(x = number of water bottles sold)</p>
                                </td>
                                <td>
                                    <div class="scenario-description">Slope (0.75):</div>
                                    <p>Each water bottle sold adds $0.75 to the profit.</p>
                                    <textarea class="text-area" placeholder="Add your own explanation of the slope..."></textarea>
                                    
                                    <div class="scenario-description" style="margin-top: 15px;">Y-Intercept (-10):</div>
                                    <p>The business begins with a loss of $10 due to the initial cooler cost.</p>
                                    <textarea class="text-area" placeholder="Add your own explanation of the y-intercept..."></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="section-box">
                    <div class="section-title">Reflection Questions</div>
                    
                    <div class="reflection-question">
                        <p>1. In your own words, what does the slope represent in each scenario?</p>
                        <textarea class="text-area" placeholder="Write your answer here..."></textarea>
                    </div>
                    
                    <div class="reflection-question">
                        <p>2. Why are both y-intercepts negative numbers? What does this tell you about running a business?</p>
                        <textarea class="text-area" placeholder="Write your answer here..."></textarea>
                    </div>
                    
                    <div class="reflection-question">
                        <p>3. How can clearly understanding slope and y-intercept help you make smart business decisions?</p>
                        <textarea class="text-area" placeholder="Write your answer here..."></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_math_8_8_F_4_d = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Linear Functions Graphic Organizer</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .section-box {
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .scenario-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 15px;
        }
        .scenario-table th {
            background-color: #233142;
            color: white;
            padding: 12px;
            text-align: left;
            border: 1px solid #ddd;
        }
        .scenario-table td {
            padding: 12px;
            border: 1px solid #ddd;
            vertical-align: top;
        }
        .scenario-description {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            margin-top: 10px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
            box-sizing: border-box;
        }
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .reflection-question {
            margin-bottom: 15px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Linear Functions: Connecting Math to Real Life</h1>
        <div class="subtitle">Interpreting Slope and Y-Intercept at Wildwood Middle School's "Courtside Café"</div>
        
        <div class="instructions">
            <p>Read each real-world scenario carefully. Then, examine the mathematical equation and the meanings of slope and y-intercept provided. Be ready to discuss how understanding these concepts helps make better business decisions.</p>
        </div>
        
        <form class="form-container">
            <div class="organizer-sheet">
                <div class="section-box">
                    <table class="scenario-table">
                        <thead>
                            <tr>
                                <th>Real-World Scenario</th>
                                <th>Mathematical Equation</th>
                                <th>Interpretation of Slope and Y-Intercept</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="scenario-description">Scenario 1: Cupcake Sales</div>
                                    <p>Students buy initial supplies (plates, napkins, cups) at a one-time cost of $30. It costs $1.50 per cupcake batch baked. Each cupcake batch sells for $6.</p>
                                </td>
                                <td>
                                    <div class="scenario-description">Profit Equation:</div>
                                    <p>Profit = 4.50x – 30</p>
                                    <p>(x = number of cupcake batches sold)</p>
                                </td>
                                <td>
                                    <div class="scenario-description">Slope (4.50):</div>
                                    <p>Each cupcake batch sold adds $4.50 to the profit.</p>
                                    <textarea class="text-area" placeholder="Add your own explanation of the slope...">The slope is $4.50, which means the students make $4.50 more money for each batch of cupcakes they sell. This is their profit per batch because $6.00 - $1.50 = $4.50.</textarea>
                                    
                                    <div class="scenario-description" style="margin-top: 15px;">Y-Intercept (-30):</div>
                                    <p>The business begins with a loss of $30 due to initial supplies.</p>
                                    <textarea class="text-area" placeholder="Add your own explanation of the y-intercept...">The y-intercept is -$30 which means the students start off losing $30 before they sell anything. This is because they had to buy supplies like plates and napkins first.</textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="scenario-description">Scenario 2: Water Bottle Sales</div>
                                    <p>Students buy an initial cooler for $10. Each water bottle costs $0.50 to buy and sells for $1.25.</p>
                                </td>
                                <td>
                                    <div class="scenario-description">Profit Equation:</div>
                                    <p>Profit = 0.75x – 10</p>
                                    <p>(x = number of water bottles sold)</p>
                                </td>
                                <td>
                                    <div class="scenario-description">Slope (0.75):</div>
                                    <p>Each water bottle sold adds $0.75 to the profit.</p>
                                    <textarea class="text-area" placeholder="Add your own explanation of the slope...">The slope is $0.75, which shows that students make 75 cents profit for each water bottle. They buy bottles for 50 cents and sell them for $1.25, so $1.25 - $0.50 = $0.75 profit per bottle.</textarea>
                                    
                                    <div class="scenario-description" style="margin-top: 15px;">Y-Intercept (-10):</div>
                                    <p>The business begins with a loss of $10 due to the initial cooler cost.</p>
                                    <textarea class="text-area" placeholder="Add your own explanation of the y-intercept...">The y-intercept is -$10 which means they start with a $10 loss because they had to buy a cooler first. They need to sell water bottles to make up for this starting cost.</textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="section-box">
                    <div class="section-title">Reflection Questions</div>
                    
                    <div class="reflection-question">
                        <p>1. In your own words, what does the slope represent in each scenario?</p>
                        <textarea class="text-area" placeholder="Write your answer here...">The slope represents how much profit the students make each time they sell one more item. For cupcakes, they make $4.50 more profit for each batch sold. For water bottles, they make $0.75 more profit for each bottle sold. The slope shows how quickly they can earn money with each product.</textarea>
                    </div>
                    
                    <div class="reflection-question">
                        <p>2. Why are both y-intercepts negative numbers? What does this tell you about running a business?</p>
                        <textarea class="text-area" placeholder="Write your answer here...">Both y-intercepts are negative because the students had to spend money before they could start selling anything. The cupcake business needed $30 for supplies, and the water bottle business needed $10 for a cooler. This shows that businesses often need to invest money upfront before they can make a profit. This is called startup costs.</textarea>
                    </div>
                    
                    <div class="reflection-question">
                        <p>3. How can clearly understanding slope and y-intercept help you make smart business decisions?</p>
                        <textarea class="text-area" placeholder="Write your answer here...">Understanding slope and y-intercept helps you make smart business decisions because the slope tells you how much profit you make per item, so you know which products earn more money. The y-intercept shows your starting costs, so you know how much money you need to begin. You can also figure out how many items you need to sell to break even (reach $0 profit). For example, water bottles have a smaller profit per item, but lower startup costs.</textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_math_8_8_F_4_d = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Linear Functions Graphic Organizer</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .section-box {
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .scenario-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 15px;
        }
        .scenario-table th {
            background-color: #233142;
            color: white;
            padding: 12px;
            text-align: left;
            border: 1px solid #ddd;
        }
        .scenario-table td {
            padding: 12px;
            border: 1px solid #ddd;
            vertical-align: top;
        }
        .scenario-description {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .writing-space {
            width: 100%;
            margin-top: 10px;
            border: 1px solid #aaa;
            border-radius: 5px;
            min-height: 60px;
            background-image: linear-gradient(#e5e5e5 1px, transparent 1px);
            background-size: 100% 25px;
            position: relative;
            box-sizing: border-box;
        }
        .reflection-question {
            margin-bottom: 20px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Linear Functions: Connecting Math to Real Life</h1>
        <div class="subtitle">Interpreting Slope and Y-Intercept at Wildwood Middle School's "Courtside Café"</div>
        
        <div class="instructions">
            <p>Read each real-world scenario carefully. Then, examine the mathematical equation and the meanings of slope and y-intercept provided. Be ready to discuss how understanding these concepts helps make better business decisions.</p>
        </div>
        
        <div class="organizer-sheet">
            <div class="section-box">
                <table class="scenario-table">
                    <thead>
                        <tr>
                            <th>Real-World Scenario</th>
                            <th>Mathematical Equation</th>
                            <th>Interpretation of Slope and Y-Intercept</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="scenario-description">Scenario 1: Cupcake Sales</div>
                                <p>Students buy initial supplies (plates, napkins, cups) at a one-time cost of $30. It costs $1.50 per cupcake batch baked. Each cupcake batch sells for $6.</p>
                            </td>
                            <td>
                                <div class="scenario-description">Profit Equation:</div>
                                <p>Profit = 4.50x – 30</p>
                                <p>(x = number of cupcake batches sold)</p>
                            </td>
                            <td>
                                <div class="scenario-description">Slope (4.50):</div>
                                <p>Each cupcake batch sold adds $4.50 to the profit.</p>
                                <p>My explanation:</p>
                                <div class="writing-space"></div>
                                
                                <div class="scenario-description" style="margin-top: 15px;">Y-Intercept (-30):</div>
                                <p>The business begins with a loss of $30 due to initial supplies.</p>
                                <p>My explanation:</p>
                                <div class="writing-space"></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="scenario-description">Scenario 2: Water Bottle Sales</div>
                                <p>Students buy an initial cooler for $10. Each water bottle costs $0.50 to buy and sells for $1.25.</p>
                            </td>
                            <td>
                                <div class="scenario-description">Profit Equation:</div>
                                <p>Profit = 0.75x – 10</p>
                                <p>(x = number of water bottles sold)</p>
                            </td>
                            <td>
                                <div class="scenario-description">Slope (0.75):</div>
                                <p>Each water bottle sold adds $0.75 to the profit.</p>
                                <p>My explanation:</p>
                                <div class="writing-space"></div>
                                
                                <div class="scenario-description" style="margin-top: 15px;">Y-Intercept (-10):</div>
                                <p>The business begins with a loss of $10 due to the initial cooler cost.</p>
                                <p>My explanation:</p>
                                <div class="writing-space"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div class="section-box">
                <div class="section-title">Reflection Questions</div>
                
                <div class="reflection-question">
                    <p>1. In your own words, what does the slope represent in each scenario?</p>
                    <div class="writing-space" style="min-height: 80px;"></div>
                </div>
                
                <div class="reflection-question">
                    <p>2. Why are both y-intercepts negative numbers? What does this tell you about running a business?</p>
                    <div class="writing-space" style="min-height: 80px;"></div>
                </div>
                
                <div class="reflection-question">
                    <p>3. How can clearly understanding slope and y-intercept help you make smart business decisions?</p>
                    <div class="writing-space" style="min-height: 80px;"></div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_math_8_8_F_4_d = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>On Track to Success: Understanding Profit Functions</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
        }
        th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
        }
        th {
            background-color: #f2f2f2;
        }
        .equation {
            text-align: center;
            font-weight: bold;
            margin: 15px 0;
            font-size: 18px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>On Track to Success</h1>
        <div class="subtitle">A Math Story About Fundraising</div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">Key Vocabulary:</div>
            <strong>Profit</strong> - The money left over after paying all costs<br>
            <strong>Rate of change</strong> - How quickly one value changes compared to another (slope)<br>
            <strong>Y-intercept</strong> - The starting value in an equation (where x = 0)
        </div>
        
        <p>It was basketball season at Wildwood Middle School in Columbia, South Carolina, and the eighth-graders wanted new uniforms and equipment. Mia, Carlos, Kayla, and Aisha decided to run a "Courtside Café" snack stand at home games to raise the money.</p>
        
        <p>"We've spent $30 just to get started," explained Carlos. "That's for plates, cups, napkins—stuff we only buy once."</p>
        
        <p>"I figured out it costs us $1.50 to bake one batch of cupcakes, and we sell each batch for $6," Mia added.</p>
        
        <p>"Let's make a chart," Kayla suggested. "We can see exactly how our profit changes with each batch we sell."</p>
        
        <table>
            <tr>
                <th>Batches Sold</th>
                <th>Profit</th>
            </tr>
            <tr>
                <td>0</td>
                <td>-$30</td>
            </tr>
            <tr>
                <td>1</td>
                <td>-$25.50</td>
            </tr>
            <tr>
                <td>2</td>
                <td>-$21.00</td>
            </tr>
            <tr>
                <td>3</td>
                <td>-$16.50</td>
            </tr>
            <tr>
                <td>10</td>
                <td>$15.00</td>
            </tr>
        </table>
        
        <p>"Look at our table," Kayla said. "Even if we sell zero batches, we're already at negative $30—that's our startup cost."</p>
        
        <p>"And every batch we sell adds $4.50 to our profit," said Aisha. "That's our rate of change!"</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Math Insight!</div>
            <p>When you see a pattern where each increase in x creates the same change in y, you're looking at a linear relationship. The rate of change is the slope of the line!</p>
        </div>
        
        <p>Later, Aisha suggested also selling bottled water for extra profit. "We only have to pay $10 once for the cooler, and then 50 cents for each bottle we buy. We can sell each bottle for $1.25."</p>
        
        <p>Together, they wrote this equation for water sales profit:</p>
        
        <div class="equation">
            Profit = 0.75x - 10
        </div>
        
        <p>"This slope means each water bottle sold adds 75 cents to our profit," Carlos said.</p>
        
        <p>"The negative ten dollars—the y-intercept—means we start off losing money because of buying the cooler," Mia explained.</p>
        
        <div class="instructions">
            <h3>Think About It:</h3>
            <p>In the water bottle equation, what does the 0.75 represent? What does the -10 represent? How many water bottles would they need to sell to break even (have $0 profit)?</p>
        </div>
        
        <p>After their first basketball game, the friends counted their money. "We sold 10 batches of cupcakes and 40 bottles of water," Kayla reported proudly.</p>
        
        <p>Aisha smiled, "Now we know exactly how math helps us make good business choices!"</p>
    </div>
</body>
</html>`;

export const teacherResource_math_8_8_F_4_d = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher Resource: On Track to Success</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .header-note {
            background-color: #ffe8e8;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 20px;
            text-align: center;
            font-weight: bold;
            border: 1px solid #ffcccc;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .section {
            background-color: #f9f9f9;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            border-left: 4px solid #233142;
        }
        .objectives {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .discussion {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .implementation {
            background-color: #fff8dc;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .assessment {
            background-color: #f0e6ff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #9966cc;
        }
        h2, h3, h4 {
            color: #233142;
            margin-top: 0;
        }
        ul, ol {
            padding-left: 20px;
        }
        li {
            margin-bottom: 8px;
        }
        .sc-standards {
            background-color: #e0f7fa;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #b2ebf2;
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Teacher Resource: On Track to Success</h1>
        <div class="header-note">FOR TEACHER USE ONLY</div>
        
        <div class="section">
            <h3>Resource Summary</h3>
            <p>This student resource presents a narrative about middle school students operating a fundraising snack stand during basketball games. The story contextualizes linear functions, demonstrating how slope represents rate of change (profit per item sold) and y-intercept represents initial value (startup costs). Students explore these concepts through both a data table and an equation.</p>
        </div>
        
        <div class="objectives">
            <h3>Learning Objectives</h3>
            <ul>
                <li>Interpret slope as rate of change in a real-world context</li>
                <li>Interpret y-intercept as initial value in a real-world context</li>
                <li>Connect numerical, verbal, and algebraic representations of linear functions</li>
                <li>Analyze how changes in parameters affect profit in a business scenario</li>
                <li>Calculate break-even points using linear equations</li>
            </ul>
        </div>
        
        <div class="sc-standards">
            <h3>South Carolina 8th Grade Math Standards Alignment</h3>
            <ul>
                <li><strong>8.ATO.7</strong> - Understand the connections between proportional relationships, lines, and linear equations.</li>
                <li><strong>8.F.5</strong> - Describe qualitatively the functional relationship between two quantities by analyzing a graph.</li>
                <li><strong>8.F.4</strong> - Interpret the equation y = mx + b as defining a linear function, whose graph is a straight line; give examples of functions that are not linear.</li>
            </ul>
        </div>
        
        <div class="discussion">
            <h3>Discussion Questions</h3>
            <ol>
                <li><strong>Conceptual Understanding:</strong> What does the slope represent in the cupcake sales example? How does this translate to the real-world meaning of rate of change?</li>
                <li><strong>Initial Value Analysis:</strong> Why is the y-intercept negative in both the cupcake and water bottle examples? What does this tell us about starting a small business?</li>
                <li><strong>Mathematical Modeling:</strong> How did the students' mathematical understanding help them make better business decisions?</li>
                <li><strong>Comparison:</strong> Compare the profit equations for cupcakes and water bottles. Which product provides a higher profit per item sold? Which has higher startup costs?</li>
                <li><strong>Break-Even Analysis:</strong> How many water bottles would need to be sold to break even (reach $0 profit)? How does this compare to the number of cupcake batches needed?</li>
                <li><strong>Prediction:</strong> If the students sold 15 batches of cupcakes and 30 bottles of water, what would their total profit be?</li>
                <li><strong>Real-World Application:</strong> How might these same mathematical concepts be used in other business or personal finance situations?</li>
            </ol>
        </div>
        
        <div class="implementation">
            <h3>Implementation Strategies</h3>
            <h4>Before Reading:</h4>
            <ul>
                <li>Activate prior knowledge by asking students about fundraising experiences they've had or observed</li>
                <li>Review vocabulary terms: profit, rate of change, slope, y-intercept, linear function</li>
                <li>Have students make predictions about how math might be used in running a small business</li>
            </ul>
            
            <h4>During Reading:</h4>
            <ul>
                <li>Read the scenario aloud as a class first, allowing students to visualize the narrative</li>
                <li>Pause at key mathematical moments to ensure understanding</li>
                <li>Model the process of identifying slope and y-intercept in context</li>
                <li>Have students collaboratively complete the "Think About It" section before continuing</li>
            </ul>
            
            <h4>After Reading:</h4>
            <ul>
                <li>Prompt students in small groups to identify and discuss slope and y-intercept in both examples</li>
                <li>Have students graph both linear equations (cupcake and water sales) and interpret their graphs</li>
                <li>Facilitate a whole-class discussion using the provided discussion questions</li>
                <li>Extend learning by having students create their own fundraising scenario with corresponding linear equation</li>
            </ul>
            
            <h4>Differentiation:</h4>
            <ul>
                <li><strong>Support:</strong> Provide a graphic organizer that helps students identify the components of each linear equation and their real-world meanings</li>
                <li><strong>Challenge:</strong> Have advanced students determine the optimal mix of products to maximize profit given time or resource constraints</li>
                <li><strong>ELL Support:</strong> Pre-teach vocabulary and provide visual models of the mathematical concepts</li>
            </ul>
        </div>
        
        <div class="assessment">
            <h3>Assessment Opportunities</h3>
            <h4>Formative Assessment:</h4>
            <ul>
                <li>Exit Ticket: Have students write the equation for the cupcake sales in slope-intercept form and explain what each part represents</li>
                <li>Think-Pair-Share: "How many water bottles and cupcake batches would need to be sold to reach a $100 profit goal?"</li>
                <li>Visual Check: Have students graph both profit functions and label key points (y-intercept, break-even point)</li>
            </ul>
            
            <h4>Summative Assessment:</h4>
            <ul>
                <li>Create a new fundraising scenario with different costs and selling prices, then write and analyze the corresponding linear equation</li>
                <li>Compare two different fundraising options using linear equations and determine which is more profitable under different conditions</li>
                <li>Written reflection explaining how slope and y-intercept help make business decisions in real-world contexts</li>
            </ul>
        </div>
        
        <div class="section">
            <h3>Extension Activities</h3>
            <ul>
                <li><strong>Real-World Connection:</strong> Have students plan an actual fundraiser for the class or school, creating linear equations to model potential profits</li>
                <li><strong>Cross-Curricular:</strong> Partner with business education to explore how businesses use these same concepts for budgeting and forecasting</li>
                <li><strong>Technology Integration:</strong> Use spreadsheet software to create profit tables and graphs that dynamically update as variables change</li>
                <li><strong>Problem-Based Learning:</strong> Present students with a fundraising goal and have them determine the most efficient way to reach it using linear functions</li>
                <li><strong>Mathematical Modeling:</strong> Introduce constraints (like maximum number of items that can be sold) and have students optimize profit within these constraints</li>
            </ul>
        </div>
        
        <div class="section">
            <h3>Common Misconceptions and Teaching Tips</h3>
            <ul>
                <li><strong>Misconception:</strong> Students may confuse the rate of change (slope) with the total profit. Emphasize that slope represents the profit per additional item sold.</li>
                <li><strong>Misconception:</strong> Students may struggle to understand why the y-intercept is negative. Reinforce that it represents startup costs before any sales occur.</li>
                <li><strong>Teaching Tip:</strong> Use concrete examples and real money to demonstrate the concepts before moving to abstract equations.</li>
                <li><strong>Teaching Tip:</strong> Encourage students to verbalize the meaning of slope and y-intercept in complete sentences using the context of the problem.</li>
                <li><strong>Teaching Tip:</strong> Connect to students' prior knowledge about proportional relationships, emphasizing how linear functions are an extension of that concept.</li>
            </ul>
        </div>
    </div>
</body>
</html>`;

