export const graphicOrganizerEmpty_ela_2_f_3_8_g = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>R-Controlled Island Adventure Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 26px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
            font-size: 20px;
        }
        .organizer-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
        .left-section, .right-section {
            flex: 1;
            padding: 15px;
        }
        .island-stop {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
        }
        .stop-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin: 8px 0;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            background-color: #ffffff;
        }
        .sound-chart {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            background-color: white;
        }
        .chart-title {
            font-weight: bold;
            color: #233142;
            text-align: center;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 15px;
            font-size: 20px;
        }
        .chart-instructions {
            text-align: center;
            margin-bottom: 15px;
            font-size: 18px;
        }
        .sound-columns {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
        }
        .sound-column {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .column-header {
            font-weight: bold;
            margin-bottom: 10px;
            text-align: center;
            font-size: 18px;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            text-align: center;
            margin-bottom: 15px;
            font-size: 20px;
        }
        .instruction {
            margin: 5px 0;
            font-size: 18px;
        }
        @media (max-width: 768px) {
            .organizer-container {
                flex-direction: column;
            }
            .sound-columns {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>R-Controlled Island Adventure Map</h1>
        <div class="subtitle">Decoding & Encoding Vowel-R Words</div>
        
        <div class="organizer-container">
            <!-- Left Section - Island Journey Map -->
            <div class="left-section">
                <div class="section-title">Island Journey Map</div>
                
                <!-- Stop 1 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 1: Sparkling Shores</div>
                    <div class="instruction">Write one vowel-R word you heard here:</div>
                    <input type="text" class="input-field" placeholder="Write word here">
                    <div class="instruction">Break it into syllables:</div>
                    <input type="text" class="input-field" placeholder="Write syllables here">
                    <div class="instruction">Circle the vowel-R sound.</div>
                </div>
                
                <!-- Stop 2 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 2: Harbor</div>
                    <div class="instruction">Copy the word 'harbor' below. Break it into syllables. Circle the vowel-R sounds.</div>
                    <div class="instruction">Word:</div>
                    <input type="text" class="input-field" placeholder="Write word here">
                    <div class="instruction">Syllables:</div>
                    <input type="text" class="input-field" placeholder="Write syllables here">
                </div>
                
                <!-- Stop 3 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 3: Orchard</div>
                    <div class="instruction">Copy the word 'orchard' below. Break it into syllables. Circle the vowel-R sound.</div>
                    <div class="instruction">Word:</div>
                    <input type="text" class="input-field" placeholder="Write word here">
                    <div class="instruction">Syllables:</div>
                    <input type="text" class="input-field" placeholder="Write syllables here">
                </div>
                
                <!-- Stop 4 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 4: Turtle Circus</div>
                    <div class="instruction">Copy the word 'turtle' below. Break it into syllables. Circle the vowel-R sound.</div>
                    <div class="instruction">Word:</div>
                    <input type="text" class="input-field" placeholder="Write word here">
                    <div class="instruction">Syllables:</div>
                    <input type="text" class="input-field" placeholder="Write syllables here">
                </div>
            </div>
            
            <!-- Right Section - Vowel-R Sound Chart -->
            <div class="right-section">
                <div class="sound-chart">
                    <div class="chart-title">Vowel-R Sound Groups</div>
                    <div class="chart-instructions">Write the vowel-R words you found on the island in the correct sound column below. Add your own vowel-R words if you think of any!</div>
                    
                    <div class="sound-columns">
                        <!-- Column 1 -->
                        <div class="sound-column">
                            <div class="column-header">ar, air, are</div>
                            <input type="text" class="input-field" placeholder="Word 1">
                            <input type="text" class="input-field" placeholder="Word 2">
                            <input type="text" class="input-field" placeholder="Word 3">
                        </div>
                        
                        <!-- Column 2 -->
                        <div class="sound-column">
                            <div class="column-header">ear, eer</div>
                            <input type="text" class="input-field" placeholder="Word 1" style="margin-top: 35px">
                            <input type="text" class="input-field" placeholder="Word 2">
                            <input type="text" class="input-field" placeholder="Word 3">
                        </div>
                        
                        <!-- Column 3 -->
                        <div class="sound-column">
                            <div class="column-header">er, ir, ur</div>
                            <input type="text" class="input-field" placeholder="Word 1" style="margin-top: 35px">
                            <input type="text" class="input-field" placeholder="Word 2">
                            <input type="text" class="input-field" placeholder="Word 3">
                        </div>
                        
                        <!-- Column 4 -->
                        <div class="sound-column">
                            <div class="column-header">or, oar, ore, our</div>
                            <input type="text" class="input-field" placeholder="Word 1">
                            <input type="text" class="input-field" placeholder="Word 2">
                            <input type="text" class="input-field" placeholder="Word 3">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ela_2_f_3_8_g = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>R-Controlled Island Adventure Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 26px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
            font-size: 20px;
        }
        .organizer-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
        .left-section, .right-section {
            flex: 1;
            padding: 15px;
        }
        .island-stop {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
        }
        .stop-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin: 8px 0;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 18px;
            background-color: #ffffff;
        }
        .sound-chart {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            background-color: white;
        }
        .chart-title {
            font-weight: bold;
            color: #233142;
            text-align: center;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 15px;
            font-size: 20px;
        }
        .chart-instructions {
            text-align: center;
            margin-bottom: 15px;
            font-size: 18px;
        }
        .sound-columns {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
        }
        .sound-column {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .column-header {
            font-weight: bold;
            margin-bottom: 10px;
            text-align: center;
            font-size: 18px;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            text-align: center;
            margin-bottom: 15px;
            font-size: 20px;
        }
        .instruction {
            margin: 5px 0;
            font-size: 18px;
        }
        @media (max-width: 768px) {
            .organizer-container {
                flex-direction: column;
            }
            .sound-columns {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>R-Controlled Island Adventure Map</h1>
        <div class="subtitle">Decoding & Encoding Vowel-R Words</div>
        
        <div class="organizer-container">
            <!-- Left Section - Island Journey Map -->
            <div class="left-section">
                <div class="section-title">Island Journey Map</div>
                
                <!-- Stop 1 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 1: Sparkling Shores</div>
                    <div class="instruction">Write one vowel-R word you heard here:</div>
                    <input type="text" class="input-field" value="shark">
                    <div class="instruction">Break it into syllables:</div>
                    <input type="text" class="input-field" value="shark">
                    <div class="instruction">Circle the vowel-R sound.</div>
                </div>
                
                <!-- Stop 2 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 2: Harbor</div>
                    <div class="instruction">Copy the word 'harbor' below. Break it into syllables. Circle the vowel-R sounds.</div>
                    <div class="instruction">Word:</div>
                    <input type="text" class="input-field" value="harbor">
                    <div class="instruction">Syllables:</div>
                    <input type="text" class="input-field" value="har-bor">
                </div>
                
                <!-- Stop 3 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 3: Orchard</div>
                    <div class="instruction">Copy the word 'orchard' below. Break it into syllables. Circle the vowel-R sound.</div>
                    <div class="instruction">Word:</div>
                    <input type="text" class="input-field" value="orchard">
                    <div class="instruction">Syllables:</div>
                    <input type="text" class="input-field" value="or-chard">
                </div>
                
                <!-- Stop 4 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 4: Turtle Circus</div>
                    <div class="instruction">Copy the word 'turtle' below. Break it into syllables. Circle the vowel-R sound.</div>
                    <div class="instruction">Word:</div>
                    <input type="text" class="input-field" value="turtle">
                    <div class="instruction">Syllables:</div>
                    <input type="text" class="input-field" value="tur-tle">
                </div>
            </div>
            
            <!-- Right Section - Vowel-R Sound Chart -->
            <div class="right-section">
                <div class="sound-chart">
                    <div class="chart-title">Vowel-R Sound Groups</div>
                    <div class="chart-instructions">Write the vowel-R words you found on the island in the correct sound column below. Add your own vowel-R words if you think of any!</div>
                    
                    <div class="sound-columns">
                        <!-- Column 1 -->
                        <div class="sound-column">
                            <div class="column-header">ar, air, are</div>
                            <input type="text" class="input-field" value="shark">
                            <input type="text" class="input-field" value="harbor">
                            <input type="text" class="input-field" value="Clark">
                        </div>
                        
                        <!-- Column 2 -->
                        <div class="sound-column">
                            <div class="column-header">ear, eer</div>
                            <input type="text" class="input-field" value="near" style="margin-top: 35px">
                            <input type="text" class="input-field" value="hear">
                            <input type="text" class="input-field" value="clear">
                        </div>
                        
                        <!-- Column 3 -->
                        <div class="sound-column">
                            <div class="column-header">er, ir, ur</div>
                            <input type="text" class="input-field" value="Fern" style="margin-top: 35px">
                            <input type="text" class="input-field" value="circus">
                            <input type="text" class="input-field" value="turtle">
                        </div>
                        
                        <!-- Column 4 -->
                        <div class="sound-column">
                            <div class="column-header">or, oar, ore, our</div>
                            <input type="text" class="input-field" value="orchard">
                            <input type="text" class="input-field" value="shore">
                            <input type="text" class="input-field" value="Nora">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ela_2_f_3_8_g = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>R-Controlled Island Adventure Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 26px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
            font-size: 20px;
        }
        .organizer-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
        .left-section, .right-section {
            flex: 1;
            padding: 15px;
        }
        .island-stop {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
        }
        .stop-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        .writing-area {
            width: 100%;
            min-height: 40px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin: 8px 0;
            background-color: #ffffff;
            position: relative;
            box-sizing: border-box;
        }
        .sound-chart {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            background-color: white;
        }
        .chart-title {
            font-weight: bold;
            color: #233142;
            text-align: center;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 15px;
            font-size: 20px;
        }
        .chart-instructions {
            text-align: center;
            margin-bottom: 15px;
            font-size: 18px;
        }
        .sound-columns {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
        }
        .sound-column {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .column-header {
            font-weight: bold;
            margin-bottom: 10px;
            text-align: center;
            font-size: 18px;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            text-align: center;
            margin-bottom: 15px;
            font-size: 20px;
        }
        .instruction {
            margin: 5px 0;
            font-size: 18px;
        }
        .word-line {
            width: 100%;
            min-height: 35px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin: 8px 0;
            background-color: #ffffff;
        }
        @media (max-width: 768px) {
            .organizer-container {
                flex-direction: column;
            }
            .sound-columns {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>R-Controlled Island Adventure Map</h1>
        <div class="subtitle">Decoding & Encoding Vowel-R Words</div>
        
        <div class="organizer-container">
            <!-- Left Section - Island Journey Map -->
            <div class="left-section">
                <div class="section-title">Island Journey Map</div>
                
                <!-- Stop 1 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 1: Sparkling Shores</div>
                    <div class="instruction">Write one vowel-R word you heard here:</div>
                    <div class="writing-area"></div>
                    <div class="instruction">Break it into syllables:</div>
                    <div class="writing-area"></div>
                    <div class="instruction">Circle the vowel-R sound.</div>
                </div>
                
                <!-- Stop 2 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 2: Harbor</div>
                    <div class="instruction">Copy the word 'harbor' below. Break it into syllables. Circle the vowel-R sounds.</div>
                    <div class="instruction">Word:</div>
                    <div class="writing-area"></div>
                    <div class="instruction">Syllables:</div>
                    <div class="writing-area"></div>
                </div>
                
                <!-- Stop 3 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 3: Orchard</div>
                    <div class="instruction">Copy the word 'orchard' below. Break it into syllables. Circle the vowel-R sound.</div>
                    <div class="instruction">Word:</div>
                    <div class="writing-area"></div>
                    <div class="instruction">Syllables:</div>
                    <div class="writing-area"></div>
                </div>
                
                <!-- Stop 4 -->
                <div class="island-stop">
                    <div class="stop-title">STOP 4: Turtle Circus</div>
                    <div class="instruction">Copy the word 'turtle' below. Break it into syllables. Circle the vowel-R sound.</div>
                    <div class="instruction">Word:</div>
                    <div class="writing-area"></div>
                    <div class="instruction">Syllables:</div>
                    <div class="writing-area"></div>
                </div>
            </div>
            
            <!-- Right Section - Vowel-R Sound Chart -->
            <div class="right-section">
                <div class="sound-chart">
                    <div class="chart-title">Vowel-R Sound Groups</div>
                    <div class="chart-instructions">Write the vowel-R words you found on the island in the correct sound column below. Add your own vowel-R words if you think of any!</div>
                    
                    <div class="sound-columns">
                        <!-- Column 1 -->
                        <div class="sound-column">
                            <div class="column-header">ar, air, are</div>
                            <div class="word-line"></div>
                            <div class="word-line"></div>
                            <div class="word-line"></div>
                        </div>
                        
                        <!-- Column 2 -->
                        <div class="sound-column">
                            <div class="column-header">ear, eer</div>
                            <div class="word-line" style="margin-top: 35px"></div>
                            <div class="word-line"></div>
                            <div class="word-line"></div>
                        </div>
                        
                        <!-- Column 3 -->
                        <div class="sound-column">
                            <div class="column-header">er, ir, ur</div>
                            <div class="word-line" style="margin-top: 35px"></div>
                            <div class="word-line"></div>
                            <div class="word-line"></div>
                        </div>
                        
                        <!-- Column 4 -->
                        <div class="sound-column">
                            <div class="column-header">or, oar, ore, our</div>
                            <div class="word-line"></div>
                            <div class="word-line"></div>
                            <div class="word-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_ela_2_f_3_8_g = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Adventure on R-Controlled Island</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
            font-size: 28px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
            font-size: 18px;
        }
        p {
            line-height: 1.8;
            font-size: 18px;
            margin-bottom: 20px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
            font-size: 18px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .r-word {
            font-weight: bold;
            color: #0066cc;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Adventure on R-Controlled Island</h1>
        <div class="subtitle">A Story with Special R Sounds</div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">R-Controlled Words in Our Story:</div>
            <strong>shark</strong> • <strong>harbor</strong> • <strong>horse</strong> • <strong>orchard</strong> • <strong>bird</strong> • <strong>circus</strong>
        </div>
        
        <div class="instructions">
            <p>Read the story. Listen for words with r sounds.</p>
        </div>
        
        <p>Five friends land on a secret island. They meet <span class="r-word">Clark</span> the <span class="r-word">Shark</span> at <span class="r-word">Sparkling</span> Shores.</p>
        
        <p>"Follow me to the <span class="r-word">harbor</span>," says <span class="r-word">Clark</span>.</p>
        
        <p><span class="r-word">Nora</span> the <span class="r-word">Horse</span> waits by an <span class="r-word">orchard</span>.</p>
        
        <p><span class="r-word">Fern</span> the <span class="r-word">Bird</span> sings near <span class="r-word">Turtle</span> <span class="r-word">Circus</span>.</p>
        
        <p>Together, the friends <span class="r-word">explore</span> and learn new <span class="r-word">words</span> with special vowel and R sounds.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Fun Fact!</div>
            <p>When a vowel is followed by the letter r, it makes a special sound. These are called r-controlled vowels!</p>
        </div>
    </div>
</body>
</html>`;

export const teacherResource_ela_2_f_3_8_g = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher Guide: Adventure on R-Controlled Island</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        h2 {
            color: #233142;
            border-bottom: 1px solid #ddd;
            padding-bottom: 5px;
        }
        h3 {
            color: #233142;
            margin-top: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .teacher-header {
            background-color: #233142;
            color: white;
            padding: 10px 15px;
            border-radius: 8px 8px 0 0;
            font-weight: bold;
            letter-spacing: 1px;
        }
        .objectives {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .guidance {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .assessment {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .summary {
            background-color: #f8f9fa;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #e9ecef;
        }
        ul, ol {
            margin-bottom: 15px;
            line-height: 1.6;
        }
        li {
            margin-bottom: 8px;
        }
        .highlight {
            background-color: #ffffcc;
            padding: 2px;
        }
        .teacher-note {
            font-style: italic;
            color: #555;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .objectives, .guidance, .assessment {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="teacher-header">TEACHER RESOURCE - NOT FOR STUDENT DISTRIBUTION</div>
        <h1>Teacher Guide: Adventure on R-Controlled Island</h1>
        
        <div class="summary">
            <h2>Resource Summary</h2>
            <p>"Adventure on R-Controlled Island" is a short reading passage for 2nd grade students featuring words with vowel-r combinations. The story follows five friends who explore an island and encounter characters with names that highlight r-controlled vowel sounds, helping students recognize and practice these phonetic patterns.</p>
        </div>
        
        <div class="objectives">
            <h2>Lesson Objectives</h2>
            <p><strong>Primary Objective:</strong> Students will decode and encode multisyllabic words with vowel-r combinations (ar, air, are, ear, eer, er, ere, eir, ir, or, oar, ore, our, ur).</p>
            
            <h3>SC Standards Alignment:</h3>
            <ul>
                <li>ELA.P.2 - Demonstrate understanding of spoken words, syllables, and sounds (phonemes).</li>
                <li>ELA.P.2.3 - Decode regularly spelled two-syllable words with long and short vowels, and r-controlled vowels.</li>
            </ul>
        </div>
        
        <div class="guidance">
            <h2>Teaching Guidance</h2>
            
            <h3>Before Reading:</h3>
            <ul>
                <li>Introduce r-controlled vowels by explaining that when a vowel is followed by 'r', it makes a special sound that is neither long nor short.</li>
                <li>Review the different r-controlled patterns that students will encounter: ar, er, ir, or, ur.</li>
                <li>Display a list of sample words for each pattern (car, her, bird, for, turn).</li>
                <li>Model proper pronunciation, emphasizing the r-controlled vowel sound.</li>
            </ul>
            
            <h3>During Reading:</h3>
            <ul>
                <li>Read aloud slowly, emphasizing vowel-r sounds (e.g., har-bor, or-chard, tur-tle).</li>
                <li>Pause after each sentence to identify the vowel-r word and segment syllables with students.</li>
                <li>Ask students: "What vowel-r sounds do you hear?" and "Can you tell me other words with this sound?"</li>
                <li>Use hand gestures to help students segment and blend syllables (clap or tap for each syllable).</li>
                <li>Have students take turns reading sentences and identifying r-controlled words.</li>
            </ul>
            
            <h3>After Reading:</h3>
            <ul>
                <li>Reinforce learning by listing new vowel-r words on the board and practicing blending syllables together.</li>
                <li>Create a class chart of r-controlled words sorted by pattern (ar, er, ir, or, ur).</li>
                <li>Have students practice writing select words from the story, focusing on the r-controlled spelling patterns.</li>
            </ul>
        </div>
        
        <div class="assessment">
            <h2>Assessment Suggestions</h2>
            
            <h3>Formative Assessment:</h3>
            <ul>
                <li><strong>Word Hunt:</strong> Have students hunt for additional r-controlled words in classroom books or materials.</li>
                <li><strong>Sound Sorting:</strong> Provide a list of words for students to sort by r-controlled vowel pattern.</li>
                <li><strong>Syllable Segmentation:</strong> Have students clap out and identify syllables in multisyllabic r-controlled words.</li>
            </ul>
            
            <h3>Summative Assessment:</h3>
            <ul>
                <li><strong>Word Reading:</strong> Create a list of 10-15 r-controlled words for students to read individually.</li>
                <li><strong>Dictation:</strong> Read 5-8 r-controlled words for students to spell correctly.</li>
                <li><strong>Story Extension:</strong> Have students write 2-3 additional sentences for the story using r-controlled words.</li>
            </ul>
        </div>
        
        <div class="guidance">
            <h2>Differentiation Strategies</h2>
            
            <h3>For Students Needing Support:</h3>
            <ul>
                <li>Focus on one r-controlled pattern at a time.</li>
                <li>Provide word cards with the r-controlled portion highlighted.</li>
                <li>Use manipulatives (letter tiles, magnetic letters) to build and segment words.</li>
                <li>Create a visual anchor chart of r-controlled patterns with example words and pictures.</li>
            </ul>
            
            <h3>For Advanced Students:</h3>
            <ul>
                <li>Challenge students to find more complex multisyllabic words with r-controlled vowels.</li>
                <li>Have students create their own short stories using r-controlled words.</li>
                <li>Introduce less common r-controlled patterns (ear, eer, ere, eir, oar, our).</li>
                <li>Explore exceptions and unusual r-controlled spellings.</li>
            </ul>
        </div>
        
        <div class="assessment">
            <h2>Extension Activities</h2>
            <ul>
                <li><strong>R-Controlled Island Map:</strong> Have students create a map of the island from the story, labeling locations with r-controlled words.</li>
                <li><strong>Character Creation:</strong> Students invent new characters with r-controlled names to add to the story.</li>
                <li><strong>Word Family Books:</strong> Create mini-books focused on specific r-controlled patterns.</li>
                <li><strong>Sound Concentration:</strong> Play a matching game with r-controlled words and corresponding pictures.</li>
                <li><strong>Vowel-R Scavenger Hunt:</strong> Hide r-controlled words around the classroom for students to find and categorize.</li>
            </ul>
        </div>
        
        <p class="teacher-note">Remember to model proper pronunciation of r-controlled vowels. Some students may need additional practice distinguishing between different r-controlled patterns. Consider providing opportunities for repeated reading of the passage to build fluency with these word patterns.</p>
    </div>
</body>
</html>`;