import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import '../../../styles/GlobalStyle.css'
import { Select, Button, Icon } from 'antd'
import PersonAvatar from '../../../customcomponents/PersonAvatar';
import ReactToPrint from 'react-to-print';
const Option = Select.Option;
import { InlineMathText } from '../../edvizv2/Edviz';

class HTMLAssessmentViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            originalHtml: props.htmlContent || '',
            instanceId: `html-assessment-viewer-${Date.now()}`, // Unique ID for CSS scoping
            studentId: ''
        };

        this.printRef = React.createRef();
        this.formContainerRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.originalHtml) {
            this.renderHtmlContent();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.htmlContent !== this.props.htmlContent) {
            this.setState({
                originalHtml: this.props.htmlContent,
                instanceId: `html-assessment-viewer-${Date.now()}` // Generate new ID on content change
            }, this.renderHtmlContent);
        }
    }

    renderHtmlContent() {
        try {
            this.setState({ isLoading: true, error: null });

            if (this.formContainerRef.current && this.state.originalHtml) {
                // Use the instance ID from state for CSS scoping
                this.formContainerRef.current.id = this.state.instanceId;

                // Extract and process any style tags from the HTML
                const htmlContent = this.processCssStyles(this.state.originalHtml, this.state.instanceId);

                // Insert the processed HTML content
                this.formContainerRef.current.innerHTML = htmlContent;

                // Find and replace formula-box elements with InlineMathText components
                this.replaceFormulaBoxElements(this.formContainerRef.current);

                // Process all forms in the document
                this.processFormsInViewMode(this.formContainerRef.current);

                // Add inline styles to .subtitle and .section-box elements
                this.addInlineStylesToElements(this.formContainerRef.current);
            }

            this.setState({ isLoading: false });
        } catch (err) {
            console.error('Error rendering HTML content:', err);
            this.setState({
                isLoading: false,
                error: err.message
            });
        }
    }

    replaceFormulaBoxElements(container) {
        if (!container) return;

        // Find all formula-box elements
        const formulaBoxes = container.querySelectorAll('.formula-box');

        if (formulaBoxes.length === 0) {
            //console.log('No formula-box elements found');
            return;
        }

        // Replace each formula-box with InlineMathText
        formulaBoxes.forEach((box, index) => {
            const latexContent = box.textContent.trim();

            // Create a container for the InlineMathText component
            const mountPoint = document.createElement('span');
            mountPoint.className = 'formula-mount-point';
            mountPoint.setAttribute('data-formula-index', index);

            // Replace the formula-box with the mount point
            box.parentNode.replaceChild(mountPoint, box);

            // Render the InlineMathText component into the mount point
            try {
                ReactDOM.render(
                    <InlineMathText text={latexContent} />,
                    mountPoint
                );
            } catch (err) {
                console.error(`Error rendering InlineMathText for formula ${index}:`, err);
                // Fallback: just show the LaTeX content if rendering fails
                mountPoint.textContent = latexContent;
            }
        });
    }

    processCssStyles(htmlContent, instanceId) {
        // Create a temporary div to parse the HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;

        // Find all style tags
        const styleTags = tempDiv.querySelectorAll('style');

        // If there are no style tags, return the original HTML
        if (styleTags.length === 0) {
            return htmlContent;
        }

        // Process each style tag
        styleTags.forEach(styleTag => {
            // Get the CSS content
            const cssContent = styleTag.textContent;

            // Create a new scoped CSS by prefixing all selectors with the instance ID
            const scopedCss = this.scopeCssSelectors(cssContent, `#${instanceId}`);

            // Replace the original CSS with the scoped version
            styleTag.textContent = scopedCss;
        });

        // Return the modified HTML content
        return tempDiv.innerHTML;
    }

    scopeCssSelectors(cssContent, scopePrefix) {
        // Handle nested rules like @media or @keyframes
        const handleNestedRules = (css) => {
            return css.replace(
                /(@media[^{]+|@keyframes[^{]+)({)([\s\S]+?)}/g,
                (match, atRule, openBrace, content) => {
                    // Process the content inside the at-rule
                    const processedContent = this.scopeCssSelectors(content, scopePrefix);
                    return `${atRule}${openBrace}${processedContent}}`;
                }
            );
        };

        // First handle nested rules
        const processedWithNestedRules = handleNestedRules(cssContent);

        // Then process standard rules (but skip those inside already processed at-rules)
        return processedWithNestedRules.replace(
            /([^{}]+)({[^{}]*})/g,
            (match, selectors, rules) => {
                // Skip if this is inside an at-rule (already processed)
                if (selectors.trim().startsWith('@')) {
                    return match;
                }

                // Process each selector group
                const scopedSelectors = selectors
                    .split(',')
                    .map(selector => {
                        selector = selector.trim();

                        // Skip @-rules
                        if (selector.startsWith('@')) {
                            return selector;
                        }

                        // Add the scope prefix to the selector
                        return `${scopePrefix} ${selector}`;
                    })
                    .join(', ');

                // Return the scoped selector with its rules
                return `${scopedSelectors}${rules}`;
            }
        );
    }

    processFormsInViewMode(formContainer) {
        if (!formContainer) return;

        // Find all forms in the container
        const forms = formContainer.querySelectorAll('form');

        forms.forEach(form => {
            // Prevent default form submission
            form.addEventListener('submit', (e) => {
                e.preventDefault();
            });

            // Disable all form inputs to make it view-only
            const inputs = form.querySelectorAll('input, select, textarea, button');
            inputs.forEach(input => {
                input.disabled = true;
            });
        });

        // Remove any submit buttons
        const submitButtons = formContainer.querySelectorAll('button[type="submit"], input[type="submit"], .form-submit-button');
        submitButtons.forEach(button => {
            button.remove();
        });

        // Remove submit button container if exists
        const submitBtnContainers = formContainer.querySelectorAll('.submit-button-container');
        submitBtnContainers.forEach(container => {
            container.remove();
        });

        // Add CSS for the loading spinner animation
        this.addSpinnerStyles();
    }

    addInlineStylesToElements(container) {
        if (!container) return;

        // Add margin-top to .subtitle elements
        const subtitleElements = container.querySelectorAll('.subtitle');
        subtitleElements.forEach(element => {
            element.style.marginTop = '16px';
        });

        // Add page-break-inside-avoid to .section-box elements
        const sectionBoxElements = container.querySelectorAll('.section-box');
        sectionBoxElements.forEach(element => {
            element.style.pageBreakInside = 'avoid';
        });

        // Add page-break-inside-avoid to .paragraph-section elements
        const paragraphSectionElements = container.querySelectorAll('.paragraph-section');
        paragraphSectionElements.forEach(element => {
            element.style.pageBreakInside = 'avoid';
        });

        // Add page-break-inside-avoid to .fun-fact elements
        const funFactElements = container.querySelectorAll('.fun-fact');
        funFactElements.forEach(element => {
            element.style.pageBreakInside = 'avoid';
        });
    }

    addSpinnerStyles() {
        // Add keyframes for spinner animation if not present
        if (!document.getElementById('spinner-keyframes')) {
            const styleSheet = document.createElement('style');
            styleSheet.id = 'spinner-keyframes';
            styleSheet.textContent = `
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `;
            document.head.appendChild(styleSheet);
        }
    }

    studentChange = (value) => {
        if (value !== this.state.studentId) {
            // Reset the view when student changes
            this.setState({
                studentId: value,
                instanceId: `html-assessment-viewer-${Date.now()}`
            }, this.renderHtmlContent);
        }
    }

    renderLoadingSpinner() {
        return (
            <div style={{ padding: '2rem', textAlign: 'center' }}>
                <div style={{
                    display: 'inline-block',
                    width: '40px',
                    height: '40px',
                    border: '4px solid rgba(0, 0, 0, 0.1)',
                    borderLeftColor: '#3f51b5',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite'
                }}></div>
                <p style={{ marginTop: '1rem' }}>Loading assessment...</p>
            </div>
        );
    }

    renderErrorMessage() {
        return (
            <div style={{
                backgroundColor: '#ffebee',
                border: '1px solid #ffcdd2',
                color: '#c62828',
                padding: '15px',
                borderRadius: '5px'
            }}>
                <h3 style={{ marginTop: 0, fontWeight: 'bold' }}>Error Loading Assessment</h3>
                <p>{this.state.error}</p>
                <button
                    onClick={() => this.processHtmlContent()}
                    style={{
                        backgroundColor: '#c62828',
                        color: 'white',
                        border: 'none',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginTop: '10px'
                    }}
                >
                    Try Again
                </button>
            </div>
        );
    }

    // This method is called when the component unmounts, to clean up any ReactDOM render points
    componentWillUnmount() {
        // Find all formula mount points and unmount React components
        if (this.formContainerRef.current) {
            const mountPoints = this.formContainerRef.current.querySelectorAll('.formula-mount-point');
            mountPoints.forEach(mountPoint => {
                ReactDOM.unmountComponentAtNode(mountPoint);
            });
        }
    }

    render() {
        const { isLoading, error } = this.state;

        return (
            <div className="html-form-viewer" style={{
                fontFamily: 'Arial, sans-serif',
                maxWidth: this.props.maxWidth || '100%',
                margin: '0 auto'
            }}>
                {!this.props.hasOwnProperty('showPrint') ||
                    (this.props.hasOwnProperty('showPrint') && this.props.showPrint) ?
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                size={'large'}
                                type='primary'
                                className="btn-vl mb-2"
                            >
                                <Icon type="printer" />
                                Print
                            </Button>
                        }
                        content={() => this.printRef.current}
                    /> : ''}

                {/* Loading spinner */}
                {isLoading && this.renderLoadingSpinner()}

                {/* Error message */}
                {!isLoading && error && this.renderErrorMessage()}

                {/* Form container */}
                {!isLoading && !error && (
                    <div
                        className="page-break-inside-avoid"
                        ref={this.printRef}
                        id={this.state.instanceId}
                    >
                        {/* Form title if provided */}
                        {this.props.title && (
                            <h2 style={{
                                fontSize: '1.5rem',
                                marginBottom: '1rem',
                                color: '#333'
                            }}>
                                {this.props.title}
                            </h2>
                        )}
                        <div
                            ref={this.formContainerRef}
                            className="form-container"
                        ></div>
                    </div>
                )}
            </div>
        );
    }
}

export default HTMLAssessmentViewer;